import React, { useEffect, useState } from "react";
import ProgramCard, {
  cardTypes,
} from "../../../../components/ProgramCard/Card";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getAllUpcomingEvents } from "./api/getAllUpcomingEvents";
import styles from "./PrijavljeneAktivnosti.module.css";

function PrijavljeneAktivnosti({ userId, onClick }) {
  const { authToken } = useAuthentication();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getAllUpcomingEvents(authToken);
        const allEvents = Object.keys(response).map((key) => ({
          activityType: key,
          activities: response[key],
        }));
        setEvents(allEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (authToken) fetchEvents();
  }, [authToken]);

  const isEmpty = !(events.length > 0);
  return (
    <div>
      {isEmpty ? (
        <div className={styles.emptyContainer}>
          <span className={styles.emptySpan}>
            Trenutno nemate prijavljenih aktivnosti.
          </span>
        </div>
      ) : (
        <div className="subpage-wrapper">
          {events?.map((event, index) => (
            <div key={index} className="subpage-wrapper">
              {event?.activities?.map((item, index) => (
                <ProgramCard
                  key={index}
                  variant={
                    userId === "Polaznik"
                      ? cardTypes.defaultCard
                      : cardTypes.editCard
                  }
                  title={item.name}
                  userId={userId}
                  brojPredavanja={userId === "Voditelj" && item.brojPredavanja}
                  datum={item.dateFrom || item.date}
                  image={item.imageURL}
                  opis={item.description}
                  polaznici={userId === "Polaznik" && item.polaznikCount}
                  voditelji={userId === "Polaznik" && item.voditeljiCount}
                  onClick={() => onClick(event.activityType, item.id)}
                  preostaloMjesta={userId === "Voditelj" && item.emptySeats}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default PrijavljeneAktivnosti;
