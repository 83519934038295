import styles from "./RadioChoices.module.css";

const RadioChoices = ({
  title,
  choices,
  error,
  onBlur,
  onChange,
  name,
  value,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      {choices &&
        choices.length > 0 &&
        choices.map((item, index) => (
          <label className={styles.optionLabel} key={index}>
            <input
              style={{ borderColor: error ? "red" : "#d1d9e2" }}
              key={index}
              type="radio"
              name={name}
              onBlur={onBlur}
              checked={value === item}
              onChange={onChange}
              value={item}
              className={styles.option}
            />
            <p className={styles.optionLabelText}>{item}</p>
          </label>
        ))}
    </div>
  );
};
export default RadioChoices;
