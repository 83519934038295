import { getEvents } from "./api/getEvents";

export const handleDatesSet = (
  info,
  selectedRooms,
  accessToken,
  setEvents,
  setStartDate,
  setEndDate
) => {
  const startDate = new Date(info.startStr);
  const endDate = new Date(info.endStr);
  const firstDayOfMonth = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 2,
    2
  );
  setStartDate(firstDayOfMonth);
  setEndDate(lastDayOfMonth);
  getEvents(
    firstDayOfMonth,
    lastDayOfMonth,
    selectedRooms,
    accessToken,
    setEvents
  );
};

export const selectStyles = {
  backgroundColor: "#FFFFFF",
  borderColor: "#f2f0f0",
  borderWidth: "2px",
  borderStyle: "solid",
  fontSize: "18px",
  fontWeight: "500",
  color: "#5F6D7E",
  fontFamily: "Source Sans Pro, sans-serif",
  width: "520px",
  height: "55px",
  borderRadius: "8px",
  padding: "6px 5px",
  border: "1px solid #D1D9E2 !important",
  boxShadow: "none",
  "&:focus": {
    border: "0 !important",
  },
};

export const handleInputChange = (
  name,
  value,
  setFormValues,
  setSelectedRoomId,
  setSelectedTimeStartLive,
  setSelectedTimeEndLive
) => {
  if (name === "roomId") {
    setSelectedRoomId(value);
    setSelectedTimeStartLive("0");
    setSelectedTimeEndLive({ label: "Odaberi" });
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      timeFrom: "",
      timeTo: "",
    }));
  } else {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }
};

export const handleKlijentChange = (
  event,
  setSearchValue,
  setIsSearchVisible
) => {
  const { value } = event.target;
  setSearchValue(value);
  setIsSearchVisible(true);
};

export const handleKlijentClick = (
  value,
  name,
  surname,
  setSearchValue,
  setFormValues,
  setIsSearchVisible
) => {
  setSearchValue(name + " " + surname);
  setFormValues((prevValues) => ({
    ...prevValues,
    clientId: value,
  }));
  setIsSearchVisible(false);
};

export const handleStartTimeChange = (
  event,
  setSelectedTimeStart,
  setFormValues
) => {
  const selectedValue = event.target.value;
  setSelectedTimeStart(selectedValue);
  setFormValues((preValues) => ({
    ...preValues,
    timeFrom: selectedValue,
  }));
};

export const handleEndTimeChange = (
  event,
  setSelectedTimeEnd,
  setFormValues
) => {
  const selectedValue = event.target.value;
  setSelectedTimeEnd(selectedValue);
  setFormValues((preValues) => ({
    ...preValues,
    timeTo: selectedValue,
  }));
};

export const postData = (eventType, formValues) => {
  switch (eventType) {
    case "therapy":
      return {
        eventType: formValues.eventType,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        description: formValues.description,
        roomId: formValues.roomId,
        clientId: formValues.clientId,
        clientOutsideIPD: formValues.outsideClient,
      };
    case "individual_supervision":
      return {
        eventType: formValues.eventType,
        supervisionType: "individual",
        name: formValues.name,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        description: formValues.description,
        roomId: formValues.roomId,
        clientId: formValues.clientId,
        clientName: formValues.outsideClient,
      };
    case "other":
      return {
        eventType: formValues.eventType,
        name: formValues.name,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        description: formValues.description,
        roomId: formValues.roomId,
      };
    default:
      return [];
  }
};

export const putData = (eventType, formValues) => {
  switch (eventType) {
    case "therapy":
      return {
        eventType: formValues.eventType,
        eventId: formValues.eventId,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        description: formValues.description,
        roomId: formValues.roomId,
        clientId: formValues.clientId,
        clientOutsideIPD: formValues.outsideClient,
      };
    case "individual_supervision":
      return {
        eventType: formValues.eventType,
        eventId: formValues.eventId,
        supervisionType: "individual",
        name: formValues.name,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        description: formValues.description,
        roomId: formValues.roomId,
        clientId: formValues.clientId,
        clientName: formValues.outsideClient,
      };
    case "other":
      return {
        eventType: formValues.eventType,
        eventId: formValues.eventId,
        name: formValues.name,
        date: formValues.date,
        timeFrom: formValues.timeFrom,
        timeTo: formValues.timeTo,
        description: formValues.description,
        roomId: formValues.roomId,
      };
    default:
      return [];
  }
};

export const transformEventName = (eventType) => {
  switch (eventType) {
    case "therapy":
      return "Terapija";
    case "individual_supervision":
      return "Supervizija";
    case "other":
      return "Ostalo";
    default:
      return "Event";
  }
};

export const addOneHourToTime = (time) => {
  const [hours, minutes] = time.split(":").map(Number);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  date.setHours(date.getHours() + 1);

  const newHours = String(date.getHours()).padStart(2, "0");
  const newMinutes = String(date.getMinutes()).padStart(2, "0");

  return `${newHours}:${newMinutes}`;
};
