import React from "react";
import RenderTableHeader from "../../../../FakultativnaRadionicaEditCreate/PolazniciTable/RenderTableHeader";
import "../../../../FakultativnaRadionicaEditCreate/PolazniciTable/TablePolaznici.css";
import RenderTableData from "./RenderTableData";

function TablePolaznik({ columnNames = [], data, userData }) {
  return (
    <table className="table-polaznici" style={{ width: "70%", marginTop: 30 }}>
      <thead>
        <RenderTableHeader columnNames={columnNames} userData={userData} />
      </thead>
      <tbody>
        <RenderTableData data={data} userData={userData} />
      </tbody>
    </table>
  );
}

export default TablePolaznik;
