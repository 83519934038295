import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const deleteEvidentionData = async (
  userId,
  intensiveId,
  accessToken
) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/userEvidentionDataIntensive?userId=${userId}&intensiveId=${intensiveId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error deleting users evidention data: ", error);
    throw error;
  }
};
