import React from "react";
import { BeatLoader } from "react-spinners";
import styles from "./LoaderModal.module.css";

function LoaderModal() {
  return (
    <div className={styles.loadingContainer}>
      <BeatLoader color="#cb9c2d" />
    </div>
  );
}

export default LoaderModal;
