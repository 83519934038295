import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CrossBtn from "../../../assets/img/crossBtn.png";
import Filter from "../../../assets/svg/filter.svg";
import Reset from "../../../assets/svg/reset.svg";
import ProgramCard, { cardTypes } from "../../../components/ProgramCard/Card";
import ReusableTabs from "../../../components/TabMenu/Tabs";
import { tabsData } from "../../../components/TabMenu/utils";
import { useCreateEventState } from "../../../context/CreateEventContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import { getAllFacultativeWorkshops } from "../../Prijavnice/PolaznikVoditelj/FakultativneRadionice/api/getAllFacultativeWorkshops";
import { getGroupSupervisions } from "../../Prijavnice/PolaznikVoditelj/GrupneSupervizije/api/getGroupSupervisions";
import { getAllSummer } from "../../Prijavnice/PolaznikVoditelj/LjetniIntenzivi/api/getAllSummer";
import { getAllEModules } from "../../Prijavnice/PolaznikVoditelj/ModulUživo/api/getAllEModules";
import { getAllWeekend } from "../../Prijavnice/PolaznikVoditelj/VikendIntenzivi/api/getAllWeekend";
import { getAllSupervisors } from "../../terapeuti/api/getAllSupervisors";
import { OstaloMockData } from "./Ostalo/MockProba";
import {
  customStyles,
  getSupervisions,
  handleCreateNewEModul,
  handleCreateNewIntenziv,
  handleCreateNewRadionica,
  handleCreateNewSupervizija,
  handleEditButton,
  handleEditEModulButton,
  handleEditIntenzivButton,
  handleEditSupervizijaButton,
  ProgramCardList,
  supervisionMonths,
  supervisionTypes,
} from "./utils";

function ReferadaProgrami({ userId }) {
  const { selectedTab } = useParams();
  const { authToken } = useAuthentication();
  const { resetCreateEventData } = useCreateEventState();
  const URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "one");
  const [data, setData] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [supervisors, setSupervisors] = useState([]);
  const [selectedType, setSelectedType] = useState("all");
  const [selectedSupervisor, setSelectedSupervisor] = useState("all");
  const [selectedIntenziv, setSelectedIntenziv] = useState({
    label: "Ljetni",
    value: 0,
  });
  const [selectedMonth, setSelectedMonth] = useState("all");

  useEffect(() => {
    if (selectedTab === undefined) {
      setCurrentTabIndex("one");
    }
  }, [selectedTab, navigate, currentTabIndex]);

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
    navigate(`/programi/${newValue}`);
    setSelectValue("aktivno");
  };

  const options = [
    { value: "aktivno", label: "Aktivno" },
    { value: "odradjeno", label: "Odrađeno" },
  ];

  const mapIndexToApiParam = {
    one: "1.stupanj",
    two: "2.stupanj",
    three: "3.stupanj",
    four: "4.stupanj",
    ten: "vt",
    eleven: "roditeljstvo",
    twelve: "rpo",
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption.value);
  };

  const handleIntenzivChange = (selectedOption) => {
    setSelectedIntenziv(selectedOption);
  };

  const handleSupervisorChange = (selectedOption) => {
    setSelectedSupervisor(selectedOption.value);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  const handleSelectChange = () => {
    if (selectValue === "aktivno") {
      setSelectValue("odradjeno");
    } else {
      setSelectValue("aktivno");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllSupervisors(authToken);
        const updatedResponse = response.users.map((supervisor) => ({
          value: supervisor.id,
          label: `${supervisor.firstName} ${supervisor.lastName}`,
        }));

        setSupervisors([{ label: "Svi", value: "all" }, ...updatedResponse]);
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken]);

  const fetchDataForTab = async (tabIndex) => {
    const apiParam = mapIndexToApiParam[tabIndex];
    const apiUrlProgrami = `${URL}/api/programs/degree/${apiParam}`;
    const programs = ["one", "two", "three", "four", "ten", "eleven", "twelve"];

    try {
      let response;
      if (programs.includes(tabIndex)) {
        const progData = await axios.get(apiUrlProgrami, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        response = progData.data;
        setData(response);
      } else if (tabIndex === "five") {
        response = await getAllFacultativeWorkshops(authToken);
        setData(response.active);
      } else if (tabIndex === "six") {
        const res1 = await getAllSummer(authToken);
        const res2 = await getAllWeekend(authToken);
        response = [...res1.active, ...res2.active];
        setData(response);
      } else if (tabIndex === "seven") {
        response = await getGroupSupervisions(authToken);
        setData(response.active);
      } else {
        response = await getAllEModules(authToken);
        setData(response.active);
      } //fali ostalo(pauzirani produženi program) nema endpointa
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (authToken) fetchDataForTab(currentTabIndex);
  }, [currentTabIndex, selectValue, authToken]);
  const handleFilter = async (type, supervisor, month) => {
    const response = await getSupervisions(type, supervisor, month, authToken);
    setData(selectValue === "aktivno" ? response.active : response.finished);
  };
  const handleReset = async () => {
    setSelectedType("all");
    setSelectedMonth("all");
    setSelectedSupervisor("all");
    await handleFilter("all", "all", "all");
    setFiltersOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <ReusableTabs
        currentTabIndex={currentTabIndex}
        handleTabChange={handleTabChange}
        tabsData={tabsData}
      />
      {currentTabIndex === "one" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="one"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "two" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="two"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "three" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="three"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "four" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="four"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "five" && (
        <>
          <Box className="header-section-intenzivi">
            <div className="select-container">
              <Select
                options={options}
                onChange={handleSelectChange}
                styles={customStyles}
                defaultValue={options[0]}
                isSearchable={false}
              />
            </div>
            <div className="buttonContainer-intenzivnost">
              <button
                className="btn-create-intenzivnost"
                onClick={() => {
                  handleCreateNewRadionica(navigate);
                  resetCreateEventData();
                }}
              >
                Kreiraj novu radionicu
                <img src={CrossBtn} className="cross-btn-icon" />
              </button>
            </div>
          </Box>
          <div className="subpage-wrapper">
            {data
              ?.sort((a, b) => new Date(a.date) - new Date(b.date))
              ?.map((item, index) => (
                <ProgramCard
                  key={index}
                  variant={cardTypes.editCard}
                  title={item.name}
                  datum={item.date || item.dateFrom}
                  userId={userId}
                  opis={item.description}
                  image={item.imageURL}
                  polaznici={item.polaznikCount}
                  voditelji={item.voditeljiCount}
                  editClick={() =>
                    handleEditButton(
                      item.id,
                      navigate,
                      authToken,
                      resetCreateEventData
                    )
                  }
                  onClick={() =>
                    navigate(
                      `/pojedina-prijavnica/fakultativne-radionice/${item.id}`
                    )
                  }
                />
              ))}
          </div>
        </>
      )}
      {currentTabIndex === "six" && (
        <>
          <Box className="header-section-intenzivi">
            <div className="select-container">
              <Select
                options={options}
                styles={customStyles}
                onChange={handleSelectChange}
                defaultValue={options[0]}
                isSearchable={false}
              />
            </div>
            <div className="select-container">
              <Select
                options={[
                  { label: "Ljetni", value: 0 },
                  { label: "Vikend", value: 1 },
                ]}
                styles={customStyles}
                onChange={handleIntenzivChange}
                value={selectedIntenziv}
                isSearchable={false}
              />
            </div>
            <div className="buttonContainer-intenzivnost">
              <button
                className="btn-create-intenzivnost"
                onClick={() => {
                  handleCreateNewIntenziv(navigate);
                  resetCreateEventData();
                }}
              >
                Kreiraj intenziv
                <img src={CrossBtn} className="cross-btn-icon" />
              </button>
            </div>
          </Box>
          <div className="subpage-wrapper">
            {data
              ?.filter((intensive) =>
                selectedIntenziv.value === 0
                  ? intensive.isWeekend === false
                  : intensive.isWeekend === true
              )
              ?.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom))
              ?.map((item, index) => (
                <ProgramCard
                  key={index}
                  variant={cardTypes.editCard}
                  title={item.name}
                  datum={item.dateFrom}
                  userId={userId}
                  opis={item.description}
                  image={item.imageURL}
                  polaznici={item.polaznikCount}
                  voditelji={item.voditeljiCount}
                  editClick={() =>
                    handleEditIntenzivButton(
                      item.id,
                      item.isWeekend,
                      navigate,
                      authToken,
                      resetCreateEventData
                    )
                  }
                  onClick={() =>
                    navigate(`/pojedina-prijavnica/vikend-intenzivi/${item.id}`)
                  }
                />
              ))}
          </div>
        </>
      )}
      {currentTabIndex === "seven" && (
        <>
          <Box className="header-section-intenzivi">
            <div className="buttonContainer-intenzivnost">
              <button
                className="btn-create-intenzivnost"
                style={{ gap: 30 }}
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                Filtriraj
                <img src={Filter} className="cross-btn-icon" />
              </button>
            </div>
            <div className="select-container">
              <Select
                options={options}
                styles={customStyles}
                onChange={handleSelectChange}
                defaultValue={options[0]}
                isSearchable={false}
              />
            </div>
            <div className="buttonContainer-intenzivnost">
              <button
                className="btn-create-intenzivnost"
                onClick={() => {
                  handleCreateNewSupervizija(navigate);
                  resetCreateEventData();
                }}
              >
                Kreiraj superviziju
                <img src={CrossBtn} className="cross-btn-icon" />
              </button>
            </div>
          </Box>
          {filtersOpen && (
            <div className="supervision-filters-container">
              <div className="supervision-selects-container">
                <div className="supervision-select-container">
                  <label className="supervision-select-labels" htmlFor="">
                    Vrsta supervizije
                  </label>
                  <Select
                    options={supervisionTypes}
                    styles={customStyles}
                    onChange={handleTypeChange}
                    defaultValue={supervisionTypes[0]}
                    isSearchable={false}
                  />
                </div>
                <div className="supervision-select-container">
                  <label className="supervision-select-labels" htmlFor="">
                    Supervizor
                  </label>
                  <Select
                    options={supervisors}
                    styles={customStyles}
                    onChange={handleSupervisorChange}
                    defaultValue={supervisors[0]}
                    isSearchable={false}
                  />
                </div>
                <div className="supervision-select-container">
                  <label className="supervision-select-labels" htmlFor="">
                    Mjesec održavanja
                  </label>
                  <Select
                    options={supervisionMonths}
                    styles={customStyles}
                    onChange={handleMonthChange}
                    defaultValue={supervisionMonths[0]}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="filter-btns">
                <button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #CFD7E0",
                    boxShadow: "4px 30px 64px 0px rgba(95, 109, 126, 0.1)",
                    borderRadius: "5px",
                    padding: "6px 10px",
                  }}
                  onClick={() => handleReset()}
                >
                  <img src={Reset} alt="" />
                </button>
                <button
                  style={{
                    height: 40,
                    backgroundColor: "#CBBA92",
                    border: "1px solid #CFD7E0",
                    boxShadow: "4px 30px 64px 0px rgba(95, 109, 126, 0.1)",
                    borderRadius: "5px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                  onClick={() =>
                    handleFilter(
                      selectedType,
                      selectedSupervisor,
                      selectedMonth
                    )
                  }
                >
                  Primjeni
                </button>
              </div>
            </div>
          )}
          <div className="subpage-wrapper">
            {data
              ?.sort((a, b) => new Date(a.date) - new Date(b.date))
              ?.map((item, index) => (
                <ProgramCard
                  key={index}
                  variant={cardTypes.editCard}
                  title={item.name}
                  datum={item.date}
                  userId={userId}
                  opis={item.description}
                  image={item.imageURL}
                  polaznici={item.polaznikCount}
                  voditelji={item.voditeljiCount}
                  editClick={() =>
                    handleEditSupervizijaButton(
                      item.id,
                      navigate,
                      authToken,
                      resetCreateEventData
                    )
                  }
                  onClick={() =>
                    navigate(
                      `/pojedina-prijavnica/grupne-supervizije/${item.id}`
                    )
                  }
                />
              ))}
          </div>
        </>
      )}
      {currentTabIndex === "nine" && (
        <>
          <Box className="header-section-intenzivi">
            <div className="select-container">
              <Select
                options={options}
                styles={customStyles}
                onChange={handleSelectChange}
                defaultValue={options[0]}
                isSearchable={false}
              />
            </div>
            <div className="buttonContainer-intenzivnost">
              <button
                className="btn-create-intenzivnost"
                onClick={() => {
                  handleCreateNewEModul(navigate);
                  resetCreateEventData();
                }}
              >
                Kreiraj novi modul
                <img src={CrossBtn} className="cross-btn-icon" />
              </button>
            </div>
          </Box>
          <div className="subpage-wrapper">
            {data
              ?.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom))
              ?.map((item, index) => (
                <ProgramCard
                  key={index}
                  variant={cardTypes.editCard}
                  title={item.name}
                  datum={item.dateFrom}
                  userId={userId}
                  opis={item.description}
                  image={item.imageURL}
                  polaznici={item.polaznikCount}
                  voditelji={item.voditeljiCount}
                  editClick={() =>
                    handleEditEModulButton(
                      item.id,
                      navigate,
                      authToken,
                      resetCreateEventData
                    )
                  }
                  onClick={() =>
                    navigate(`/pojedina-prijavnica/moduli-uživo/${item.id}`)
                  }
                />
              ))}
          </div>
        </>
      )}
      {currentTabIndex === "ten" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="ten"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "eleven" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="eleven"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "twelve" && (
        <ProgramCardList
          data={data}
          cardType={cardTypes.defaultCard}
          tabIndex="twelve"
          userId={userId}
          navigate={navigate}
        />
      )}
      {currentTabIndex === "thirteen" && (
        <div className="subpage-wrapper">
          {OstaloMockData.map((item, index) => (
            <ProgramCard
              key={index}
              variant={cardTypes.defaultCard}
              title={item.title}
              userId={userId}
              opis={item.description}
              image={item.image}
              polaznici={item.polaznici}
              onClick={() => navigate(`/pojediniprogram/${item.id}/one`)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ReferadaProgrami;
