import React, { useState } from "react";
import Left from "../../../../assets/img/ArrowLeft.png";
import useWindowResize from "../../../../components/Hooks/ResizeHook";
import CarouselCard from "../../../dashboard/components/CarouselCard";
import styles from "./Carousel.module.css";
import { handleNext, handlePrev } from "./utils";

function Carousel({ data, onClick }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const breakpoint = 1100;
  const width = useWindowResize();
  const itemsPerPage = 3;
  let slicedData;
  if (width > breakpoint) {
    slicedData = data
      ?.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom))
      .slice(activeIndex, activeIndex + itemsPerPage);
  } else {
    slicedData = data;
  }

  return (
    <div className={styles.carouselContainer}>
      {data.length > 3 && (
        <button
          className={styles.leftButton}
          onClick={() => handlePrev(setActiveIndex, data)}
        >
          <img alt="" src={Left} />
        </button>
      )}
      {slicedData.map((item, index) => {
        const isActive = index === 0;
        const isPrevious = false;
        const isNext = index === 1;
        const isHidden = false;
        return (
          <div
            key={index}
            className={`${styles.cardItem} ${
              isActive ? `${styles["active"]}` : ""
            } ${isPrevious ? `${styles["previous"]}` : ""} ${
              isNext ? `${styles["next"]}` : ""
            } ${isHidden ? `${styles["hidden"]}` : ""}`}
          >
            <CarouselCard
              title={item.name}
              image={item.imageURL}
              date={item.dateFrom}
              userId={"Polaznik"}
              onClick={() => onClick(item.id)}
            />
          </div>
        );
      })}
      {data.length > 3 && (
        <div className={styles.carouselButtons}>
          <div className={styles.spacer} />
          <button
            className={styles.rightButton}
            onClick={() => handleNext(setActiveIndex, data)}
          >
            <img alt="" src={Left} />
          </button>
        </div>
      )}
    </div>
  );
}

export default Carousel;
