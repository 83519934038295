import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Plus from "../../../../../assets/img/WhitePlus.png";
import OdobrenjeModal from "../Modal/OdobrenjeModal";
import "./OdobrenjeTable.css";

function RenderTableData({ data = [] }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    userId: null,
    firstName: "",
    lastName: "",
    applicationId: "",
  });
  const handleOnClickBtn = (item) => {
    setUserInfo((prevUserInfo) => ({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      applicationId: item.applicationId,
    }));
    setShowModal(true);
  };
  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td>
            <div className="table-odobrenje-row" style={{ color: "#2E3646" }}>
              <img
                alt=""
                src={item.imageUrl}
                className="table-odobrenje-aplikacija-avatar"
              />
              {item.firstName} {item.lastName}
            </div>
          </td>
          <td>
            <span
              onClick={() =>
                navigate(
                  `/dokumenti/aplikacije-za-upis/anketa/${item.applicationId}`
                )
              }
              className="table-odobrenje-aplikacija-link"
            >
              Link na anketu
            </span>
          </td>
          <td style={{ maxWidth: 400 }}>
            {item?.mentorReview?.length > 0 ? (
              <div>{item?.mentorReview}</div>
            ) : (
              <button
                className="table-odobrenje-aplikacija-btn"
                onClick={() => handleOnClickBtn(item)}
              >
                Dodaj komentar <img alt="" src={Plus} />
              </button>
            )}
          </td>
        </tr>
      ))}
      {showModal && (
        <OdobrenjeModal
          onClose={() => setShowModal(false)}
          firstName={userInfo.firstName}
          lastName={userInfo.lastName}
          userId={userInfo.userId}
          applicationId={userInfo.applicationId}
        />
      )}
    </>
  );
}

export default RenderTableData;
