import { debounce } from "lodash";
import { useEffect, useState } from "react";
import CheckFalse from "../../../../assets/svg/CheckFalse.svg";
import CheckTrue from "../../../../assets/svg/CheckTrue.svg";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { deleteEvidentionEntry } from "../../api/deleteEvidentionEntry";
import { addEvidentionEntry } from "../../api/postEvidentionEntry";
import { fetchMySudjelovanje, fetchUserSudjelovanje } from "../../util";
import styles from "../SudjelovanjeNaPredavanjima/SudjelovanjeNaPredavanjima.module.css";
import TerapijeModal from "../TerapijeModal/TerapijeModal";
import "./CardAccordion.css";

const AccordionItems = ({
  topic,
  variant,
  isInteraktivnoPredavanje,
  isNameClickable,
  isTP,
  isTT,
  isRar,
  isOR,
  isChecked,
  isEdit,
  evidentionUserID,
  setEvidentionData,
  userId,
  setDeleteFlag,
  deleteFlag,
  tpIttData,
}) => {
  const [eventType, setEventType] = useState("");
  const [modalEventType, setModalEventType] = useState("");
  const { authToken } = useAuthentication();
  const [showTerapijeModal, setShowTerapijeModal] = useState(false);
  const { userData } = useUserState();

  useEffect(() => {
    if (topic?.type) {
      setEventType(topic.type);
    } else {
      setEventType("regular_workshop");
    }
  }, [topic]);

  const debouncePostRequest = debounce(async () => {
    try {
      const res = await addEvidentionEntry(
        topic.id,
        evidentionUserID,
        eventType,
        authToken
      );
      if (res.status === 200) {
        if (userData.id !== evidentionUserID) {
          fetchUserSudjelovanje(evidentionUserID, authToken, setEvidentionData);
        } else {
          fetchMySudjelovanje(authToken, setEvidentionData);
        }
      }
    } catch (error) {
      console.error("Error posting evidention entry data", error);
    }
  }, 500);

  const debounceDeleteRequest = debounce(async () => {
    try {
      const res = await deleteEvidentionEntry(
        topic.id,
        evidentionUserID,
        authToken
      );

      if (res.status === 200) {
        if (userData.id !== evidentionUserID) {
          fetchUserSudjelovanje(evidentionUserID, authToken, setEvidentionData);
        } else {
          fetchMySudjelovanje(authToken, setEvidentionData);
        }
      }
    } catch (error) {
      console.error("Error deleting evidention entry data", error);
    }
  }, 500);

  const onTopicNameClick = (topic) => {
    if (
      topic.eventType === "tp2" ||
      topic.eventType === "tt5" ||
      topic.eventType === "tt6"
    ) {
      setShowTerapijeModal(false);
    } else {
      setShowTerapijeModal(true);
      setModalEventType(topic.eventType);
    }
  };

  return (
    <li className="list-item-accordion-card">
      <div className="border-bottom-li">
        {variant === "hours" ? (
          <span
            className={topic?.hours > 0 ? "topicNameLink" : ""}
            onClick={
              topic?.hours > 0 && !(isTP || isTT)
                ? () => onTopicNameClick(topic)
                : undefined
            }
          >
            {topic.name}
          </span>
        ) : (
          topic.name
        )}
        {isInteraktivnoPredavanje || isTP || isTT || isRar || isOR ? (
          <div style={{ marginRight: 74 }}>
            <label className={styles.label}>
              <input
                type="checkbox"
                className={styles.checkboxInput}
                defaultChecked={isChecked}
                onChange={(e) => {
                  if (isChecked) {
                    debounceDeleteRequest();
                  } else {
                    debouncePostRequest();
                  }
                }}
              />
              <span className={styles.checkboxCustom} />
            </label>
          </div>
        ) : (
          <>
            {variant === "hours" ? (
              <span className="hours-accordion">
                {topic.eventType === "tp2"
                  ? tpIttData?.tp2?.modules[0]?.filter(
                      (module) => module.attendance === true
                    )?.length || 0
                  : topic.eventType === "tt5"
                  ? (tpIttData?.tt5?.modules || [])
                      .flat()
                      .filter((module) => module.attendance === true).length >
                    24
                    ? 10
                    : 0
                  : topic.eventType === "tt6"
                  ? (tpIttData?.tt6?.modules || [])
                      .flat()
                      .filter((module) => module.attendance === true).length >
                    24
                    ? 10
                    : 0
                  : topic?.hours || 0}
              </span>
            ) : topic?.attendance ? (
              <span className="attended-accordion">
                <img src={CheckTrue} alt="check true" />
              </span>
            ) : (
              <span className="didnt-attend-accordion">
                <img src={CheckFalse} alt="check false" />
              </span>
            )}
          </>
        )}
        {showTerapijeModal && (
          <TerapijeModal
            userId={userId}
            topic={topic.name}
            modalEventType={modalEventType}
            onClose={() => setShowTerapijeModal(false)}
            setDeleteFlag={setDeleteFlag}
            deleteFlag={deleteFlag}
            evidentionUserID={evidentionUserID}
          />
        )}
      </div>
    </li>
  );
};

export default AccordionItems;
