import { useEffect, useState } from "react";
import ProgramCard, {
  cardTypes,
} from "../../../../components/ProgramCard/Card";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getAllFacultativeWorkshops } from "./api/getAllFacultativeWorkshops";

function FakultativneRadionice({ userId, onClick }) {
  const [facultative, setFacultative] = useState([]);
  const { authToken } = useAuthentication();

  useEffect(() => {
    const fetchFW = async () => {
      try {
        const response = await getAllFacultativeWorkshops(authToken);
        setFacultative(response.active);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    };

    if (authToken) fetchFW();
  }, [authToken]);

  return (
    <div className="subpage-wrapper">
      {facultative
        ?.sort((a, b) => new Date(a.date) - new Date(b.date))
        ?.map((item, index) => (
          <ProgramCard
            key={index}
            variant={
              userId === "Referada" ? cardTypes.editCard : cardTypes.defaultCard
            }
            title={item.name}
            userId={userId}
            brojPredavanja={userId === "Voditelj" && item.brojPredavanja}
            datum={item.date}
            image={item.imageURL}
            opis={item.description}
            polaznici={userId === "Polaznik" && item.polaznikCount}
            voditelji={userId === "Polaznik" && item.voditeljiCount}
            onClick={() => onClick(item.id)}
            preostaloMjesta={userId === "Voditelj" && item.emptySeats}
          />
        ))}
    </div>
  );
}

export default FakultativneRadionice;
