import React, { useEffect, useState } from "react";
import EvaluacijeAccordions from "./Accordions/EvaluacijeAccordions";
import ObracunAccordions from "./Accordions/ObracunAccordions";
import CertificateContainer from "./CertificateContainer/CertificateContainer";
import styles from "./Supervizije.module.css";
import RenderTable from "./Table/RenderTable";

function Supervizije({
  userId,
  polaznikLevel,
  data,
  deleteFlag,
  setDeleteFlag,
  authToken,
  evidentionUserID,
  change,
  setChange,
  tpIttData,
}) {
  const minSupervizijeSatiPraktičar = 60;
  const minSupervizijeSatiTerapeut = 150;
  const [supervizijeArray, setSupervizijeArray] = useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  const [
    evaluationOfIndividualSupervisionsArray,
    setEvaluationOfIndividualSupervisionsArray,
  ] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setSupervizijeArray([
        { evaluationOfIndividualSupervisions: array[0] },
        { supervisorConsent: array[1] },
        { groupSupervision: array[2] },
        { individualSupervision: array[3] },
      ]);
    }
  }, [data]);

  const currentIndividualneSati =
    supervizijeArray?.[3]?.individualSupervision.individualSupervision +
    // supervizijeArray?.[3]?.individualSupervision.therapistTrijada +
    supervizijeArray?.[3]?.individualSupervision.supervisantAGS;
  const remainingIndividualneSati =
    supervizijeArray?.[3]?.individualSupervision.minAttendance -
    currentIndividualneSati;
  const currentGrupneSati =
    supervizijeArray?.[2]?.groupSupervision.groupSupervisionSectionCount +
    supervizijeArray?.[2]?.groupSupervision.tp2Count +
    supervizijeArray?.[2]?.groupSupervision.tt5Count +
    supervizijeArray?.[2]?.groupSupervision.tt6Count +
    (tpIttData?.tp2?.modules[0]?.filter((module) => module.attendance === true)
      ?.length || 0) +
    ((tpIttData?.tt5?.modules || [])
      ?.flat()
      ?.filter((module) => module.attendance === true)?.length > 24
      ? 10
      : 0) +
    ((tpIttData?.tt6?.modules || [])
      ?.flat()
      ?.filter((module) => module.attendance === true)?.length > 24
      ? 10
      : 0);
  const remainingGrupneSati =
    supervizijeArray?.[2]?.groupSupervision.minAttendance - currentGrupneSati;
  useEffect(() => {
    if (supervizijeArray.length > 0) {
      const groupSupervision = supervizijeArray[2]?.groupSupervision || {};
      const individualSupervision =
        supervizijeArray[3]?.individualSupervision || {};
      const evaluationOfIndividualSupervisions =
        supervizijeArray[0]?.evaluationOfIndividualSupervisions || [];
      const groupSupervisionTopicsArray = [
        {
          name: "Grupna supervizija",
          hours: groupSupervision.groupSupervisionSectionCount || 0,
          isNameClickable: true,
          eventType: "group_supervision",
        },
        {
          name: "TP2",
          hours: groupSupervision.tp2Count || 0,
          isNameClickable: false,
          eventType: "tp2",
        },
        {
          name: "TT5",
          hours: groupSupervision.tt5Count || 0,
          isNameClickable: false,
          eventType: "tt5",
        },
        {
          name: "TT6",
          hours: groupSupervision.tt6Count || 0,
          isNameClickable: false,
          eventType: "tt6",
        },
      ];
      const individualSupervisionTopicsArray = [
        {
          name: "Individualna supervizija 1 na 1",
          hours: individualSupervision.individualSupervision || 0,
          isNameClickable: true,
          eventType: "individual_supervision",
        },
        {
          name: "Supervizant na AGS-u",
          hours: individualSupervision.supervisantAGS || 0,
          isNameClickable: true,
          eventType: "ags_supervisant",
        },
        {
          name: "Terapeut na Trijadi - radionica metodologije",
          hours: individualSupervision.therapistTrijada || 0,
          isNameClickable: true,
          eventType: "trijada_therapist",
        },
      ];

      setObracunInfo([
        {
          title: "Individualne supervizije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualSupervisionTopicsArray,
              maxTopObracun:
                supervizijeArray?.[3]?.individualSupervision.minAttendance,
            },
          ],
        },
        {
          title: "Grupne supervizije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: groupSupervisionTopicsArray,
              maxBottomObracun:
                supervizijeArray?.[2]?.groupSupervision?.minAttendance,
            },
          ],
        },
      ]);
      setEvaluationOfIndividualSupervisionsArray([
        {
          title: "Evaluacija individualnih supervizija",
          modules: [
            {
              topics: evaluationOfIndividualSupervisions,
              showTable: true,
            },
          ],
        },
      ]);
    }
  }, [supervizijeArray]);

  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <div>
        <div>
          <h1 className={styles.raniRazvojHeader}>
            Supervizije i metodologije
          </h1>
          <p className={styles.raniRazvojDescription}>
            Sati Metodologije (Trijade) imaju za cilj učenje samog terapijskog
            procesa kao i evaluaciju edukanta s ciljem dobivanja Potvrde o
            statusu edukanta kroz suglasnost dva supervizora za rad s klijentima
            pri čemu je važna sama spremnost edukanta, a ne određen broj sati.
          </p>{" "}
          <p className={styles.raniRazvojDescription2}>
            Moje trenutno stanje sati Metodologije:{" "}
            {supervizijeArray?.[3]?.individualSupervision.therapistTrijada} h
          </p>
          <p className={styles.raniRazvojDescription}>
            Za postizanje statusa{" "}
            {polaznikLevel === "praktičar" ? "Praktičara" : "Psihoterapeuta"}{" "}
            ITP-a potrebno je ostvariti minimalno{" "}
            {polaznikLevel === "praktičar"
              ? minSupervizijeSatiPraktičar
              : minSupervizijeSatiTerapeut}{" "}
            sati supervizija od čega min{" "}
            {polaznikLevel === "praktičar" ? 15 : 40} sati individualnih
            supervizija + sati Metodologije{" "}
            {polaznikLevel === "praktičar" && ""} i{" "}
            {polaznikLevel === "praktičar" ? 45 : 110} sati grupnih supervizija
            {polaznikLevel === "praktičar" && ""}.
          </p>
          <p className={styles.raniRazvojDescription2}>
            Moje trenutno stanje sati supervizija: <br /> 1) Individualne
            supervizije + Metodologija{polaznikLevel === "praktičar" && ""}
            {/**/} : {currentIndividualneSati}h (Nedostaje{" "}
            {remainingIndividualneSati}h) {polaznikLevel !== "praktičar" && ""}
            (u ovaj obračun se ne računaju sati Terapeut na Trijadi)
            <br />
            2) Grupna supervizija {polaznikLevel === "praktičar" && ""}:{" "}
            {currentGrupneSati}h (Nedostaje {remainingGrupneSati}
            h)
            <br /> <br />
            TP2- 7 sati grupne supervizije će ti se automatski upisati nakon što
            poklikaš svoje sudjelovanje na svih 7 radionica TP2
            <br /> TT5 - 10 sati grupne supervizije će ti se automatski upisati
            nakon što poklikaš svoje sudjelovanje na min 25 radionica TT5 <br />
            TT6 - 10 sati grupne supervizije će ti se automatski upisati nakon
            što poklikaš svoje sudjelovanje na min 25 radionica TT6
          </p>
        </div>
        <span className={styles.label}>Suglasnost supervizora</span>
        <RenderTable
          data={supervizijeArray?.[1]?.supervisorConsent}
          role={userId}
          evidentionUserID={evidentionUserID}
          change={change}
          setChange={setChange}
        />
        <span className={styles.label}>
          Preuzmi svoju potvrdu o statusu edukanta u školi ITP-a
        </span>
        <CertificateContainer />
        <ObracunAccordions
          data={obracunInfo}
          userId={userId}
          deleteFlag={deleteFlag}
          setDeleteFlag={setDeleteFlag}
          evidentionUserID={evidentionUserID}
          tpIttData={tpIttData}
        />
        <EvaluacijeAccordions
          userId={userId}
          data={evaluationOfIndividualSupervisionsArray}
          evidentionUserID={evidentionUserID}
          change={change}
          setChange={setChange}
        />
      </div>
    </div>
  );
}

export default Supervizije;
