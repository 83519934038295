import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Polaznik.css";
import {
  handleNavigateToRucniUnos,
  polaznikORTTOptions,
  polaznikRAROptions,
  polaznikRetarded,
} from "./utils";

const Polaznik = ({
  user,
  showButton = true,
  polaznikLevel,
  userEvidentionID,
}) => {
  let retardedUsers = [1306,338];
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const fullName = localStorage.getItem("evidentionFullName");

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const dropdownRefs = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  let dataToRender = [];
  let secondDataToRender = [];
  if (retardedUsers.includes(Number(userEvidentionID))) {
    dataToRender = polaznikRetarded;
  } else if (polaznikLevel === "rar") {
    dataToRender = polaznikRAROptions;
  } else {
    dataToRender = polaznikRAROptions;
    secondDataToRender = polaznikORTTOptions;
  }
  return (
    <div className="polaznik-container-header">
      <div className="polaznik-username">Polaznik: {fullName} </div>
      <div className="self-add-container">
        {showButton && (
          <button className="self-add-hrs" onClick={() => handleDropdown()}>
            Ručni unos sati +
          </button>
        )}
        {showDropdown && (
          <div className="rucni-unos-dropdown" ref={dropdownRefs}>
            <>
              {dataToRender.map((item, index) => {
                return (
                  <div
                    className="rucni-unos-list general-styles"
                    onClick={() => {
                      handleNavigateToRucniUnos(
                        navigate,
                        item.value,
                        polaznikLevel,
                        userEvidentionID
                      );
                      setShowDropdown(false);
                    }}
                  >
                    <span to={""} className="header-link" index={index}>
                      {item.label}
                    </span>
                  </div>
                );
              })}
              {polaznikLevel !== "rar" && (
                <>
                  {secondDataToRender.map((item, index) => {
                    return (
                      <div
                        className="rucni-unos-list general-styles"
                        onClick={() => {
                          handleNavigateToRucniUnos(
                            navigate,
                            item.value,
                            polaznikLevel,
                            userEvidentionID
                          );
                          setShowDropdown(false);
                        }}
                      >
                        <span to={""} className="header-link" index={index}>
                          {item.label}
                        </span>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default Polaznik;
