import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Question from "../../../../../../assets/img/Help.png";
import Success from "../../../../../../assets/img/Success.png";
import ArrowLeftIcon from "../../../../../../assets/svg/ArrowLeftIcon";
import Checkbox from "../../../../../../components/checkbox/Checkbox";
import CustomInput from "../../../../../../components/customInput/CustomInput";
import Modal from "../../../../../../components/Modal/Modal";
import RadioChoices from "../../../../../../components/radioChoices/RadioChoices";
import { useUserState } from "../../../../../../context/UserContext";
import { useAuthentication } from "../../../../../hooks/useAuthentication";
import Accordion from "../accordion/Accordion";
import SelectDropdown from "../selectDropdown/SelectDropdown";
import { createApplication } from "./api/createApplication";
import { getApplicationById } from "./api/getApplicationById";
import { getMyMentor } from "./api/getMyMentor";
import { getNumberOdTherapies } from "./api/getNumberOfTherapies";
import { getUsersApplications } from "./api/getUsersApplications";
import styles from "./EnrollmentForm.module.css";

const EnrollmentForm = () => {
  const [visibleForm, setVisibleForm] = useState([]);
  const [errorFields, setErrorFieds] = useState([]);
  const [application, setApplication] = useState({});
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [myMentors, setMyMentors] = useState([]);
  const [parsedApplication, setParsedApplication] = useState({});
  const [mentorOptions, setMentorOptions] = useState([]);
  const [numberOfTherapies, setNumberOfTherapies] = useState(0);
  const [allUsersApplications, setAllUsersApplications] = useState([]);
  const navigate = useNavigate();
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;

  const { programApplicationId } = useParams();

  useEffect(() => {
    const fetchApplication = async () => {
      const response = await getApplicationById(
        programApplicationId,
        authToken
      );
      setApplication(response);
      const visible = [response.course];
      setVisibleForm(visible);
      const parsedApplication = JSON.parse(response.application);
      setParsedApplication(parsedApplication);
      if (response.course === "OR") {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsOne.setValue(key, value);
        });
      } else if (response.course === "eOR") {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsTwo.setValue(key, value);
        });
      } else if (response.course === "TP") {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsThree.setValue(key, value);
        });
      } else {
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethodsFour.setValue(key, value);
        });
      }
    };
    if (authToken) fetchApplication();
  }, [authToken]);
  useEffect(() => {
    const fetchMentorAndTherapies = async () => {
      const response = await getMyMentor(userData.id, authToken);
      const numberT = await getNumberOdTherapies(userData.id, authToken);
      setMyMentors(response);
      setMentorOptions(response.map((mentor) => mentor.name));
      setNumberOfTherapies(numberT || 0);
    };
    if (authToken) fetchMentorAndTherapies();
  }, [authToken]);
  const tp = allUsersApplications?.programApplications?.find(
    (application) => application.course === "TP"
  );
  console.log(
    tp,
    allUsersApplications.map((i) => i.course)
  );
  const tpQuestions = tp && JSON.parse(tp.application);
  // OR form
  const formMethodsOne = useForm({
    defaultValues: {
      questionOr1: "",
      questionOr2: "",
      questionOr3: "",
      questionOr4: "",
      questionOr5: "",
      questionOr6: "",
      checkOr1: false,
      checkOr2: false,
    },
    mode: "all",
  });
  // eOR form
  const formMethodsTwo = useForm({
    defaultValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      choice: "",
      question5: "",
      question6: "",
      check1: false,
      check2: false,
    },
    mode: "all",
  });
  // TP form
  const formMethodsThree = useForm({
    defaultValues: {
      questionTP1: "",
      questionTP2: "",
      questionTP3: "",
      checkTP1: false,
      questionTP4: tpQuestions?.questionTP4 || "",
      choiceTP1: tpQuestions?.choiceTP1 || "",
      choiceTP2: tpQuestions?.choiceTP2 || "",
      questionTP5: numberOfTherapies || "",
      questionTP6: tpQuestions?.questionTP6 || "",
      questionTP7: tpQuestions?.questionTP7 || "",
      choiceTP3: tpQuestions?.choiceTP3 || "",
      selectTP1: tpQuestions?.selectTP1 || "",
      selectTP2: tpQuestions?.selectTP2 || "",
      questionTP8: tpQuestions?.questionTP8 || "",
    },
    mode: "all",
  });
  // TT form
  const formMethodsFour = useForm({
    defaultValues: {
      question1: "",
      select1: "",
      select2: "",
      question2: "",
      check: false,
    },
    mode: "all",
  });

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await getUsersApplications(userData.id, authToken);
        setVisibleForm(response.enrollmentOptions);
        setAllUsersApplications(response.programApplications);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    if (userId !== "Referada" && !programApplicationId)
      if (authToken) fetchApplication();
  }, [authToken]);
  const sendApplication = async (data, form) => {
    const mentorId =
      myMentors?.find((mentor) => mentor.name === JSON.parse(data).select2)
        ?.id || null;
    const requestBody = {
      application: data,
      userId: userData.id,
      programId: 1,
      course: form,
      visibleForReferada: true,
      mentorId: mentorId,
    };
    try {
      await createApplication(requestBody, authToken);
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error creating application:", error);
    }
  };

  const handleResetForm = async (formMethods) => {
    setIsFirstModalOpen(false);
    const formData = formMethods.getValues();
    console.log(formData);
    const jsonString = JSON.stringify(formData);
    let form;
    setErrorFieds(() =>
      Object.keys(formData).filter(
        (key) =>
          (formData[key] === "" || formData[key] === false) &&
          key !== "questionTP8"
      )
    );
    if (formMethods === formMethodsOne) {
      form = "OR";
    } else if (formMethods === formMethodsTwo) {
      form = "eOR";
    } else if (formMethods === formMethodsThree) {
      form = "TP";
    } else {
      form = "TT";
    }
    const err = Object.keys(formData).filter(
      (key) =>
        formData[key] === "" || formData[key] === 0 || formData[key] === false
    );
    if (err.length === 0) {
      try {
        await sendApplication(jsonString, form);
      } catch (error) {
        console.error("Error sending application:", error);
      }
    } else {
      alert("Molimo vas popunite sva polja");
    }
  };

  return (
    <div className={styles.container}>
      {isSuccessModalOpen && (
        <Modal
          image={Success}
          title={"Tvoja aplikacija je uspješno poslana."}
          buttons={[
            {
              label: "U redu",
              action: () => setIsSuccessModalOpen(false),
              className: "modal-btn-success",
            },
          ]}
        />
      )}
      {isFirstModalOpen && (
        <Modal
          image={Question}
          title={"Jeste li sigurni da želite poslati aplikaciju?"}
          description={
            "Kada pošaljete aplikaciju odgovori se ne mogu mijejnati!"
          }
          buttons={[
            {
              label: "Odustani",
              action: () => setIsFirstModalOpen(false),
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Pošalji",
              action: () => {
                handleResetForm(formMethodsThree);
              },
              className: "modal-btn-success",
            },
          ]}
        />
      )}
      <div
        className={styles.backButton}
        onClick={() =>
          userId === "Referada"
            ? navigate("/dokumenti/aplikacije-za-upis")
            : navigate("/dokumenti")
        }
      >
        <ArrowLeftIcon className={styles.backButtonArrow} />
        <p className={styles.backButtonText}>natrag</p>
      </div>
      {userId === "Referada" && (
        <span className={styles.userName}>
          Polaznik: {application?.User?.firstName} {application?.User?.lastName}
        </span>
      )}{" "}
      <div className={styles.formContainer}>
        <p className={styles.formTitle}>
          Moje aplikacije za upis višeg stupnja edukacije za psihoterapeuta
          ITP-a
        </p>
        <p className={styles.formDescription}>
          Ukoliko želiš iskazati interes za upis višeg stupnja edukacije u školi
          Integralne tjelesne psihoterapije, potrebno je ispuniti aplikaciju
          ispod i zadovoljiti sve propisane uvjete.
        </p>
        <div className={styles.form}>
          {visibleForm?.map((form, index) => (
            <Accordion
              key={index}
              isOpen={false}
              title={`Aplikacija za upis ${form}-a`}
              children={
                form === "OR" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      <p className={styles.formSectionText}>
                        Ispunjavanjem ove aplikacije izražavaš svoj interes za
                        upis 4-godišnjeg programa Osobni razvoj, kao 2. stupnja
                        i temeljnog dijela edukacije u školi Integralne tjelesne
                        psihoterapije. Škola prima ograničen broj polaznika koji
                        trebaju imati određenu spremnost i kapacitet za ovaj vid
                        rada.Na odluku o tvom prijemu u program OR utječe
                        sadržaj tvojih introspekcija, procjena tvojih voditelja
                        RAR-a o spremnosti tvog sustava za ovakav rad kao i
                        informacije iz ove aplikacije. Naglašavamo da kao škola,
                        zbog zakonskih normi kao i kontraindiciranosti u
                        edukaciju za psihoterapeute ITP-a i program Osobni
                        razvoj ne možemo primiti:
                        <br />- osobe koje su ovisnici o narkoticima i alkoholu
                        <br />- osobe sa aktivnim psihijatrijskim dijagnozama
                        i/ili osobe koje kontinuirano koriste psihofarmake
                        <br />- osobe koje su emocionalno u teškim životnim
                        fazama
                        <br />- osobe s puno psiho-emocionalne nestabilnosti
                        <br />- osobe koje su pravomoćno osuđene za neko od
                        kaznenih dijela navedenih u Zakonu o djelatnosti
                        psihoterapije Članak 6. stavak 3.
                      </p>
                      <Controller
                        control={formMethodsOne.control}
                        name="questionOr1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="questionOr1"
                            error={errorFields.find(
                              (field) => field === "questionOr1"
                            )}
                            labelText="Opiši svoje trenutno radno mjesto"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="questionOr2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="questionOr2"
                            error={errorFields.find(
                              (field) => field === "questionOr2"
                            )}
                            labelText="Navedi ako imaš dosadašnje iskustvo u savjetodavnim, psihoterapijskim ili sličnim edukacijama za rad s ljudima kao i u neposrednom stručnom radu s ljudima:"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="questionOr3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="questionOr3"
                            error={errorFields.find(
                              (field) => field === "questionOr3"
                            )}
                            labelText="Navedi ako imaš svoja osobna iskustva u radu na sebi i psihoterapiji."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="questionOr4"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="questionOr4"
                            error={errorFields.find(
                              (field) => field === "questionOr4"
                            )}
                            labelText="Opiši kratko što je tvoja motivacija za upis OR-a."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="questionOr5"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            error={errorFields.find(
                              (field) => field === "questionOr5"
                            )}
                            value={value}
                            name="questionOr5"
                            labelText="Da li već sada znaš da namjeravaš upisati trening za psihoterapeute ITP-a koji počinje na 3. godini s programom TP i nastavlja se na petoj i šestoj godini s programom TT."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="questionOr6"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="questionOr6"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            error={errorFields.find(
                              (field) => field === "questionOr6"
                            )}
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="checkOr1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "checkOr1"
                            )}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            label="Ovime potvrđujem da nemam trenutno nikakvo fizičko ili psiho-emocionalno stanje koje bi moglo biti u kontraindikaciji s programom Osobni razvoj.U slučaju da se pitaš da li je neko tvoje fizičko ili psiho-emocionalno stanje ili neki lijekovi koje uzimaš u kontraindikaciji sa upisom OR-a javi nam se na referada@ipd-center.eu kako bi dogovorili evaluacijsku terapiju s voditeljem grupe OR-a."
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsOne.control}
                        name="checkOr2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "checkOr2"
                            )}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            label="Ovime potvrđujem da u ovoj fazi života (ukoliko ne dođe do nekih nepredviđenih okolnosti) sam u mogućnosti financirati pohađanje programa četverogodišnji Osobni razvoj i potrebne individualne terapije"
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (userId !== "Referada") {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsOne);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : form === "eOR" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      <p className={styles.formSectionText}>
                        Ispunjavanje ove aplikacije izražavaš svoj interes za
                        upis 4-godišnjeg online programa Osobni razvoj, kao 2.
                        stupnja i temeljnog dijela edukacije u školi Integralne
                        tjelesne psihoterapije. Škola prima ograničen broj
                        polaznika koji trebaju imati određenu spremnost i
                        kapacitet za ovaj vid rada.Na odluku o tvom prijemu u
                        program e-OR utječe sadržaj tvojih introspekcija,
                        procjena tvojih voditelja RAR-a o spremnosti tvog
                        sustava za ovakav rada kao i informacije iz ove
                        aplikacije. Naglašavamo da kao škola zbog zakonskih
                        normi kao i kontraidiciranosti u edukaciju za
                        psihoterapeute ITP-a i program Osobni razvoj ne možemo
                        primati:
                        <br />- osobe koje su ovisnici o narkoticima i alkoholu
                        <br />- osobe sa aktivnim psihijatrijskim dijagnozama
                        i/ili osobe koje kontinuirano koriste psihofarmake
                        <br />- osobe koje su emocionalno teškim životnim fazama
                        <br />- osobe s puno psiho-emocionalne nestabilnosti
                        <br />- osobe koje su pravomoćno osuđene za neko od
                        kaznenih dijela navedenih u Zakonu o djelatnosti
                        psihoterapije Članak 6. stavak 3.
                        <br />
                        <br />
                        Za one koji upisuju e-OR kao dio edukacije iz ITP-a,
                        obveza je proći modul uživo 1x godišnje u Zagrebu, a
                        naša preporuka je i dolazak u Zagreb na procesne
                        intenzive uživo * u slučaju da u tvom gradu postoji OR
                        uživo, dobro promisli o svojoj odluci oko upisa online
                        e-OR jer neće biti moguće prebacivanje tijekom
                        školovanja
                      </p>
                      <Controller
                        control={formMethodsTwo.control}
                        name="question1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question1"
                            labelText="Opiši svoje trenutno radno mjesto"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question2"
                            labelText="Navedi ako imaš dosadašnje iskustvo u savjetodavnim, psihoterapijskim ili sličnim edukacijama za rad s ljudima kao i u neposrednom stručnom radu s ljudima:"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question3"
                            labelText="Navedi ako imaš svoja osobna iskustva u radu na sebi i psihoterapiji."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question4"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question4"
                            labelText="Opiši kratko što je tvoja motivacija za upis eOR-a."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="choice"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choice"
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            title="Da li je tvoja namjera dolaziti 1x godišnje na modul uživo u Zagreb?"
                            choices={["Da", "Ne"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question5"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question5"
                            labelText="Da li već sada znaš da namjeravaš upisati trening za psihoterapeute ITP-a koji počinje na 3. godini s programom TP i nastavlja se na petoj i šestoj godini s programom TT."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="question6"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question6"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="check1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            name="check1"
                            label="Ovime potvrđujem da nemam trenutno nikakvo fizičko ili psiho-emocionalno stanje koje bi moglo biti u kontraindikaciji s programom Osobni razvoj.U slučaju da se pitaš da li je neko tvoje fizičko ili psiho-emocionalno stanje ili neki lijekovi koje uzimaš u kontraindikaciji sa upisom OR-a javi nam se na referada@ipd-center.eu kako bi dogovorili evaluacijsku terapiju s voditeljem grupe OR-a."
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsTwo.control}
                        name="check2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            name="check2"
                            label="Ovime potvrđujem da u ovoj fazi života (ukoliko ne dođe do nekih nepredviđenih okolnosti) sam u mogućnosti financirati pohađanje programa četverogodišnji Osobni razvoj i potrebne individualne terapije"
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (userId !== "Referada") {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsTwo);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : form === "TT" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      {userId !== "Referada" ||
                        (programApplicationId === undefined && (
                          <p className={styles.formSectionText}>
                            Pozdrav,u svrhu prijave za upis u Napretni
                            terapeutski trening &#40;TT5&#41; molimo te da
                            popuniš obrazac u nastavku.
                            <br />
                            <br />
                          </p>
                        ))}
                      <Controller
                        control={formMethodsFour.control}
                        name="question1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question1"
                            labelText="Trenutno zanimanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="select1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="select1"
                            title="Navedi ime svog mentora:"
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={value}
                            initialOption="Odaberi"
                            options={["Tomislav Kuljiš", "Marija Matić"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="select2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="select2"
                            title="Navedi ime svog mentora:"
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={value}
                            initialOption="Odaberi"
                            options={["Tomislav Kuljiš", "Marija Matić"]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="question2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            name="question2"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsFour.control}
                        name="check"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              programApplicationId === undefined &&
                              onChange
                            }
                            value={value}
                            name="check"
                            label="Potvrđujem da sam upoznat sa Zakonom o djelatnosti psihoterapije u Hrvatskoj koji kaže: Djelatnost psihoterapije obavlja ovlašteni psihoterapeut ili ovlašteni savjetodavni terapeut. Za status savjetodavnog terapeuta potrebno je imati minimalno status Sveučilišnog prvostupnika (Baccalaureus) stručnog prvostupnik ili VŠS (prije Bologne) i uz to imati završenu Propedeutiku psihoterapije. Za status psihoterapeuta neki od navedenih fakulteta (medicinske znanosti, kliničke medicinske znanosti, javnog zdravstva i zdravstvene zaštite psihologije, socijalne djelatnosti, edukacijsko-rehabilitacijske znanosti, pedagogije i logopedije). U Srbiji i Sloveniji djelatnost psihoterapije još nije regulirana zakonski, ali je za očekivati da će u narednim godinama zakoni izgledati slično kao u Hrvatskoj."
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (userId !== "Referada") {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsFour);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : form === "TP" ? (
                  <div>
                    <div className={styles.formInputsContainer}>
                      <p className={styles.formSectionText}>
                        Pozdrav,u svrhu prijave za upis u Terapeutski praktikum
                        1 &#40;TP1&#41; molimo te da popuniš obrazac u nastavku.
                        <br />
                        <br />
                        Termine pohađanja TP1 biraš u nastavku obrasca a nakon
                        što prikupimo sve prijave, javit ćemo ti se s konačnim
                        terminima radionica i odgovorom na tvoju aplikaciju u
                        TP.
                        <br />U slučaju većeg broja prijavljenih nego što možemo
                        primiti prednost će imati oni koji zadovoljavaju veći
                        broj propisanih uvjeta.
                        <br />
                      </p>
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            error={errorFields.find(
                              (field) => field === "questionTP1"
                            )}
                            value={
                              !programApplicationId
                                ? userData.firstName + " " + userData.lastName
                                : value === "" || value === null
                                ? parsedApplication.question1
                                : value
                            }
                            name="questionTP1"
                            labelText="Ime i prezime"
                            disabled={true}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            error={errorFields.find(
                              (field) => field === "questionTP2"
                            )}
                            value={
                              !programApplicationId
                                ? userData.qualification
                                : value === "" || value === null
                                ? parsedApplication.question2
                                : value
                            }
                            name="questionTP2"
                            labelText="Školska sprema"
                            disabled={true}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "questionTP3"
                            )}
                            onChange={onChange}
                            value={
                              !programApplicationId
                                ? userData.city
                                : value === "" || value === null
                                ? parsedApplication.question3
                                : value
                            }
                            name="questionTP3"
                            labelText="Grad stanovanja"
                            disabled={true}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="checkTP1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Checkbox
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "checkTP1"
                            )}
                            onChange={
                              userId !== "Referada" &&
                              !programApplicationId &&
                              onChange
                            }
                            value={
                              !programApplicationId
                                ? false
                                : value === "" || value === null
                                ? parsedApplication.check1
                                : value
                            }
                            label="Potvrđujem da nisam pravomoćno osuđen/a ili optužen/a za neko od kaznenih djela navedenih u Zakonu o djelatnosti psihoterapije."
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP4"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            error={errorFields.find(
                              (field) => field === "questionTP4"
                            )}
                            value={
                              !programApplicationId
                                ? ""
                                : value === "" || value === null
                                ? parsedApplication.question4
                                : value
                            }
                            name="questionTP4"
                            labelText="Navedi ako imaš dodatne edukacije iz područja psihoterapije ili nekog sličnog rada na sebi."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="choiceTP1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choiceTP1"
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "choiceTP1"
                            )}
                            onChange={
                              userId !== "Referada" &&
                              !programApplicationId &&
                              onChange
                            }
                            value={
                              !programApplicationId
                                ? false
                                : value === "" || value === null
                                ? parsedApplication.choice1
                                : value
                            }
                            title="Imaš li riješene sve financijske obaveze prema školi od prethodnih godina što je preduvjet za upis TP-a?"
                            choices={[
                              "Da",
                              "Ne još, javit ću se računovodstvu (racunovodstvo@ipd-center.eu)",
                            ]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="choiceTP2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choiceTP2"
                            error={errorFields.find(
                              (field) => field === "choiceTP2"
                            )}
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              !programApplicationId &&
                              onChange
                            }
                            value={
                              !programApplicationId
                                ? false
                                : value === "" || value === null
                                ? parsedApplication.choice
                                : value
                            }
                            title="Uvjet za upis TP-a su ispunjene sve introspekcije do zadnjeg predavanja OR-a koje si imao/la. Imaš li ispunjene sve introspekcije do sada?"
                            choices={[
                              "Da",
                              "Ne, ali potvrđujem da ću ih ispuniti do 01.02. i javiti svom mentoru i naknadno dobiti suglasnost",
                            ]}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP5"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "questionTP5"
                            )}
                            onChange={onChange}
                            value={
                              !programApplicationId
                                ? numberOfTherapies
                                : value === "" || value === null
                                ? parsedApplication.question5
                                : value
                            }
                            name="quesitonTP5"
                            labelText="Broj tvojih programskih terapija (IPR-a) do današnjeg datuma: "
                            placeholder="Upiši"
                            disabled={true}
                          />
                        )}
                      />
                      <p className={styles.opisP}>
                        (Za upis TP-a je potrebno imati najmanje 24 programske
                        terapije unesene u softver. Ako ti fali do 4 terapije
                        mentor ti može dati uvjetan upis. Npr. dati će ti
                        suglasnost pod uvjetom da do 01.02. 2025. ispuniš
                        potreban broj terapija.)
                      </p>
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP6"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            onChange={onChange}
                            value={
                              !programApplicationId
                                ? ""
                                : value === "" || value === null
                                ? parsedApplication.question6
                                : value
                            }
                            error={errorFields.find(
                              (field) => field === "questionTP6"
                            )}
                            name="questionTP6"
                            labelText="Opiši:
                             kako sada vidiš svoje trenutne kapacitete da budeš na TP-u,
                            što misliš sada na čemu trebaš raditi kod sebe kako bi na TP2 mogao/la početi raditi s ljudima:
                            "
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP7"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "questionTP7"
                            )}
                            onChange={onChange}
                            value={
                              !programApplicationId
                                ? ""
                                : value === "" || value === null
                                ? parsedApplication.question7
                                : value
                            }
                            name="questionTP7"
                            labelText="Opiši zašto želiš upisati TP, što je tvoja motivacija da budeš terapeut:"
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="choiceTP3"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioChoices
                            name="choiceTP3"
                            error={errorFields.find(
                              (field) => field === "choiceTP3"
                            )}
                            onBlur={onBlur}
                            onChange={
                              userId !== "Referada" &&
                              !programApplicationId &&
                              onChange
                            }
                            value={
                              !programApplicationId
                                ? false
                                : value === "" || value === null
                                ? parsedApplication.choice3
                                : value
                            }
                            title="Označi u kojem terminu bi želio/željela pohađati radionice TP1 (napomena: odabirom termina, ne garantiramo da ćeš u tom terminu pohađati TP. Nakon što završe prijave i oforme se grupe, potvrdit ćemo ti s kojom grupom ćeš pohađati TP shodno mogućnostima i broju prijava)"
                            choices={[
                              "1.	GRUPA: 01.02. i 15.03. (subota od 9:00-21.15h) uživo u ZG - prednost imaju polaznici iz Ljubljane, Splita, Zagreba",
                              "2.	GRUPA: 02.02. i 16.03. (nedjelja od 9:00-21.15h) uživo u ZG - prednost imaju polaznici eOR-a, Podgorice",
                              "3.	GRUPA: 24.02./25.02./26.02./1.4./15.4./22.4. (od 18h-21:15h) (ZG uživo + BG istovremeno preko ZOOM-a u BG prostoru) ",
                            ]}
                          />
                        )}
                      />
                      <p className={styles.opisP}>
                        Predavanja za sve grupe TP1 su 23.01.2025. i 27.02.2025.
                        Vrijeme održavanja: 18h-21:15h Zagreb - uživo, ostali
                        gradovi – ZOOM
                      </p>
                      <Controller
                        control={formMethodsThree.control}
                        name="selectTP1"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="selectTP1"
                            error={errorFields.find(
                              (field) => field === "selectTP1"
                            )}
                            title="U slučaju da će neke grupe biti popunjene i neki će morati prijeći u druge grupe navedi koja bi ti alternativna grupa odgovarala:"
                            onChange={
                              userId !== "Referada" &&
                              !programApplicationId &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={
                              !programApplicationId
                                ? ""
                                : value === "" || value === null
                                ? parsedApplication.select1
                                : value
                            }
                            initialOption={
                              !programApplicationId ? "Odaberi" : value
                            }
                            options={[
                              "1.	GRUPA: 01.02. i 15.03. (subota od 9:00-21.15h) uživo u ZG - prednost imaju polaznici iz Ljubljane, Splita, Zagreba",
                              "2.	GRUPA: 02.02. i 16.03. (nedjelja od 9:00-21.15h) uživo u ZG - prednost imaju polaznici eOR-a, Podgorice",
                              "3.	GRUPA: 24.02./25.02./26.02./1.4./15.4./22.4. (od 18h-21:15h) (ZG uživo + BG istovremeno preko ZOOM-a u BG prostoru) ",
                            ]}
                            disabled={programApplicationId !== undefined}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="selectTP2"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectDropdown
                            name="selectTP2"
                            title="Mentor:"
                            error={errorFields.find(
                              (field) => field === "selectTP2"
                            )}
                            onChange={
                              userId !== "Referada" &&
                              !programApplicationId &&
                              onChange
                            }
                            onBlur={onBlur}
                            value={
                              !programApplicationId
                                ? ""
                                : value === "" || value === null
                                ? parsedApplication.select2
                                : value
                            }
                            initialOption={
                              !programApplicationId ? "Odaberi" : value
                            }
                            options={mentorOptions}
                            disabled={programApplicationId !== undefined}
                          />
                        )}
                      />
                      <Controller
                        control={formMethodsThree.control}
                        name="questionTP8"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomInput
                            onBlur={onBlur}
                            error={errorFields.find(
                              (field) => field === "questionTP8"
                            )}
                            onChange={onChange}
                            value={
                              !programApplicationId
                                ? ""
                                : value === "" || value === null
                                ? parsedApplication.question8
                                : value
                            }
                            name="questionTP8"
                            labelText="Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje."
                            placeholder="Upiši"
                            disabled={
                              userId === "Referada" ||
                              programApplicationId !== undefined
                            }
                          />
                        )}
                      />
                    </div>
                    {userId !== "Referada" &&
                      programApplicationId === undefined && (
                        <div className={styles.formButtonsContainer}>
                          <div
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.cancelButton}`
                                : styles.cancelButton
                            }
                            onClick={() => {
                              if (
                                userId !== "Referada" &&
                                programApplicationId === undefined
                              ) {
                                formMethodsFour.reset();
                                navigate(-1);
                              }
                            }}
                            disabled={true}
                          >
                            odustani
                          </div>
                          <div
                            disabled={tp}
                            className={
                              userId === "Referada"
                                ? `${styles.disabled} ${styles.sendButton}`
                                : styles.sendButton
                            }
                            onClick={() => {
                              if (userId !== "Referada")
                                handleResetForm(formMethodsThree);
                            }}
                          >
                            Pošalji prijavu
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  ""
                )
              }
            />
          ))}
          {visibleForm.length === 0 && (
            <div
              style={{
                marginTop: 30,
                boxShadow: "4px 4px 64px 0px #5f6d7e1a",
                border: "1px solid #e2e2e2",
                borderRadius: 8,
              }}
            >
              <p
                className={styles.formDescription}
                style={{ fontSize: 16, textAlign: "center", width: "98%" }}
              >
                Trenutno nemate dostupnih aplikacija
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EnrollmentForm;
