import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../assets/img/BackArrow.png";
import Image from "../../../assets/img/Intenzivi2.png";
import Success from "../../../assets/img/ModalCheckmark.png";
import Modal from "../../../components/Modal/Modal";
import { useUserState } from "../../../context/UserContext";
import {
  TitleBold,
  VoditeljiContainer,
} from "../../FakultativnaRadionicaEditCreate/EditCreate/FakultativnaRadionicaEdit.styles";
import VoditeljiCard from "../../FakultativnaRadionicaEditCreate/VoditeljiCard/VoditeljiCard";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  DropdownButton,
  Opis,
  OpisWrapper,
} from "../../pojediniProgrami/ProgramContainer/ProgramContainer.styles";
import {
  handleEditButton,
  handleEditEModulButton,
  handleEditIntenzivButton,
  handleEditSupervizijaButton,
} from "../../programi/Referada/utils";
import { addUserToPrijavnica } from "../api/addUserToPrijavnica";
import { deleteUserFromPrijavnica } from "../api/deleteUserFromPrijavnica";
import { getCurrentUser } from "../api/getCurrentUser";
import { getUserProgram } from "../api/getUserProgram";
import AGSModal from "./components/AGSModal/Modal";
import InfoContainer from "./components/InfoContainer/InfoContainer";
import TablePolaznik from "./components/TablicaPolaznik/TablePolaznik";
import styles from "./PrijavnicaContainer.module.css";

function mapIdsToArray(array) {
  return array.map((item) => item.id);
}

const typeMapping = {
  "fakultativne-radionice": "facultativeWorkshopId",
  "grupne-supervizije": "supervisionId",
  "ljetni-intenzivi": "intensiveId",
  "vikend-intenzivi": "intensiveId",
};

const defaultPrijavnicaName = "eModuleLiveId";

const getPrijavnicaName = (type) => typeMapping[type] || defaultPrijavnicaName;

function PrijavnicaContainer({
  location,
  voditelji,
  polaznici,
  prijavnicaType,
  prijavnicaData,
}) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProgram, setCurrentUserProgram] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFullOpis, setShowFullOpis] = useState(false);
  const [showFullOpis2, setShowFullOpis2] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    buttons: [],
  });
  const [polazniciInPr, setPolazniciInPr] = useState([]);
  const [voditeljiInPr, setVoditeljiInPr] = useState([]);
  const { userData } = useUserState();
  const { authToken } = useAuthentication();

  const getCurrent = async () => {
    try {
      const response = await getCurrentUser(authToken);
      setCurrentUser(response?.id);
      const responseProgram = await getUserProgram(authToken);
      setCurrentUserProgram(responseProgram?.title);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (polaznici.length > 0) {
      const polazniciIds = mapIdsToArray(polaznici);
      setPolazniciInPr(polazniciIds);
    }
    if (voditelji.length > 0) {
      const voditeljiIds = mapIdsToArray(voditelji);
      setVoditeljiInPr(voditeljiIds);
    }
    if (authToken) getCurrent();
  }, [polaznici, voditelji]);

  const prijavnicaName = getPrijavnicaName(prijavnicaType);

  const addUser = async (izlaganje) => {
    let reqBody;
    if (prijavnicaType === "grupne-supervizije") {
      reqBody = {
        [prijavnicaName]: prijavnicaData.id,
        isVoditelj: false,
        izlazemCase: izlaganje || false,
      };
    } else {
      reqBody = {
        [prijavnicaName]: prijavnicaData.id,
        isVoditelj: false,
      };
    }
    try {
      const response = await addUserToPrijavnica(
        prijavnicaType,
        reqBody,
        authToken
      );
    } catch (err) {
      console.log("Error adding user : ", err);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await deleteUserFromPrijavnica(
        prijavnicaType,
        currentUser,
        prijavnicaData.id,
        authToken
      );
    } catch (err) {
      console.log("Error adding user : ", err);
    }
  };

  const prijava = async (izlaganje) => {
    try {
      await addUser(izlaganje);
      setModalContent({
        image: Success,
        title: "Tvoja prijava je uspješna!",
        buttons: [
          {
            label: "U redu",
            action: () => window.location.reload(),
            className: "modal-btn-success",
          },
        ],
      });
      setShowRegisterModal(true);
    } catch (err) {
      console.log("Error adding user : ", err);
    }
  };

  const handlePrijavaClick = async () => {
    try {
      if (isSupervision) {
        setShowModal(true);
      } else {
        prijava();
      }
    } catch (err) {
      console.log("Error trying to apply : ", err);
    }
  };

  const handleOdustaniClick = async () => {
    try {
      await deleteUser();
      setModalContent({
        image: Success,
        title: "Tvoja odjava je uspješna!",
        buttons: [
          {
            label: "U redu",
            action: () => window.location.reload(),
            className: "modal-btn-success",
          },
        ],
      });
      setShowRegisterModal(true);
    } catch (err) {
      console.log("Error trying to cancel : ", err);
    }
  };

  const toggleOpis = () => {
    setShowFullOpis(!showFullOpis);
  };
  const toggleOpis2 = () => {
    setShowFullOpis2(!showFullOpis2);
  };
  const columnNames = ["Ime i prezime", "Aktivni program/Grupa"];
  if (userData.roleId === 6) {
    columnNames.push("Email");
  }
  const isRegistered = () => {
    if (polazniciInPr.includes(currentUser)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getCurrentPrograms = async () => {
      try {
        const responseProgram = await getUserProgram(authToken);
        setCurrentUserProgram(responseProgram?.[0]?.title);
      } catch (err) {
        console.log(err);
      }
    };

    if (authToken) getCurrentPrograms();
  }, [authToken]);

  const isSupervision =
    prijavnicaType === "grupne-supervizije" &&
    (currentUserProgram === "TP2" ||
      currentUserProgram === "TT5" ||
      currentUserProgram === "TT6")
      ? true
      : false;

  return (
    <div className={styles.container}>
      <div className={styles.backRow} onClick={() => navigate(-1)}>
        <img alt="" src={Arrow} />
        <span className={styles.backSpan}>Natrag na popis aktivnosti</span>
      </div>
      <div className={styles.pojedinaImageContainer}>
        <img
          className={styles.pojedinaSekcijaImage}
          src={prijavnicaData.imageURL || Image}
          alt="prijavnica"
        />
      </div>
      <div className={styles.title}>
        {prijavnicaData.name}
        {userData.roleId === 6 ? (
          <button
            className={styles.editBtn}
            onClick={() => {
              prijavnicaType === "fakultativne-radionice"
                ? handleEditButton(prijavnicaData?.id, navigate, authToken)
                : prijavnicaType.includes("intenzivi")
                ? handleEditIntenzivButton(
                    prijavnicaData?.id,
                    prijavnicaData?.isWeekend,
                    navigate,
                    authToken
                  )
                : prijavnicaType === "grupne-supervizije"
                ? handleEditSupervizijaButton(
                    prijavnicaData?.id,
                    navigate,
                    authToken
                  )
                : prijavnicaType === "moduli-uživo"
                ? handleEditEModulButton(
                    prijavnicaData?.id,
                    navigate,
                    authToken
                  )
                : console.log("Greška");
            }}
          >
            Uredi
          </button>
        ) : userData.roleId !== 5 ? (
          <>
            {!isRegistered() ? (
              <button
                className={
                  prijavnicaData.capacity <= polaznici.length
                    ? styles.disabledBtn
                    : styles.prijavaBtn
                }
                onClick={() => handlePrijavaClick()}
                disabled={prijavnicaData.capacity <= polaznici.length}
              >
                Prijavi se
              </button>
            ) : (
              <button
                className={styles.odjavaBtn}
                onClick={() => handleOdustaniClick()}
              >
                Odustani
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {isSupervision && (
        <div>
          <label className={styles.label}>
            <input
              type="checkbox"
              className={styles.checkboxInput}
              checked={prijavnicaData.izlazemCase}
            />
            <span className={styles.checkboxCustom} />
            Na superviziji izlažem svoj case
          </label>
        </div>
      )}
      <div className={styles.opisiWrapper}>
        <OpisWrapper>
          <Opis
            style={{
              WebkitLineClamp: showFullOpis ? "unset" : 3,
              whiteSpace: showFullOpis ? "normal" : "",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {prijavnicaData?.description?.split("\n")?.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Opis>
          <DropdownButton onClick={toggleOpis}>
            {showFullOpis ? "Prikaži manje" : "Prikaži više"}
          </DropdownButton>
        </OpisWrapper>
        <OpisWrapper style={{ width: "30%" }}>
          {prijavnicaData?.aditionalDescription !== "" && (
            <>
              <Opis
                style={{
                  WebkitLineClamp: showFullOpis2 ? "unset" : 3,
                  whiteSpace: showFullOpis2 ? "normal" : "",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {prijavnicaData.aditionalDescription
                  ?.split("\n")
                  ?.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </Opis>
              <DropdownButton onClick={toggleOpis2}>
                {showFullOpis2 ? "Prikaži manje" : "Prikaži više"}
              </DropdownButton>
            </>
          )}
        </OpisWrapper>
      </div>
      <div className={styles.infoContainer}>
        <InfoContainer
          datumStart={prijavnicaData.dateFrom}
          datumEnd={prijavnicaData.dateTo}
          datum={prijavnicaData.date}
          location={location}
          capacity={prijavnicaData.capacity}
          gprSati={prijavnicaData.gprHours}
          price={prijavnicaData.price}
          prijavnicaType={prijavnicaType}
          timeFrom={prijavnicaData.timeFrom}
          timeTo={prijavnicaData.timeTo}
          supervisionType={prijavnicaData.supervisionType}
        />
      </div>
      <TitleBold>Voditelji {voditelji.length}</TitleBold>
      <VoditeljiContainer>
        {voditelji &&
          voditelji.length > 0 &&
          voditelji.map((item, index) => (
            <VoditeljiCard
              key={index}
              userId={"Polaznik"}
              voditeljAvatar={item.imageUrl}
              voditeljIme={item.firstName}
              voditeljPrezime={item.lastName}
              voditeljEmail={item.emailAddress}
              onClick={() => navigate(`/djelatnik/4/${item.id}`)}
            />
          ))}
      </VoditeljiContainer>
      {polaznici.length === 0 ? (
        <Opis>Trenutno nema polaznika.</Opis>
      ) : (
        <TablePolaznik
          columnNames={columnNames}
          data={polaznici}
          userData={userData}
        />
      )}

      {showModal && (
        <AGSModal onClose={() => setShowModal(false)} prijava={prijava} />
      )}
      {showRegisterModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.title}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default PrijavnicaContainer;
