export const polaznikPrijavniceTabs = [
  // { value: "one", label: "Fakultativne radionice" },
  { value: "two", label: "Ljetni intenzivi" },
  { value: "three", label: "Vikend intenzivi" },
  // { value: "four", label: "Modul uživo" },
  { value: "five", label: "Prijavljene aktivnosti" },
];

export const polaznikOR3PrijavniceTabs = [
  // { value: "one", label: "Fakultativne radionice" },
  { value: "two", label: "Ljetni intenzivi" },
  { value: "three", label: "Vikend intenzivi" },
  // { value: "four", label: "Grupne supervizije" },
  // { value: "five", label: "Modul uživo" },
  { value: "six", label: "Prijavljene aktivnosti" },
];
