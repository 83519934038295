import React, { useEffect, useState } from "react";
import ProgramCard, {
  cardTypes,
} from "../../../../components/ProgramCard/Card";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getAllEModules } from "./api/getAllEModules";

function ModulUživo({ userId, onClick }) {
  const { authToken } = useAuthentication();
  const [modules, setModules] = useState([]);
  useEffect(() => {
    const fetchSupervisions = async () => {
      try {
        const response = await getAllEModules(authToken);
        setModules(response?.active);
      } catch (error) {
        console.error("Error fetching supervisions:", error);
      }
    };

    if (authToken) fetchSupervisions();
  }, [authToken]);
  return (
    <div className="subpage-wrapper">
      {modules
        ?.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom))
        ?.map((item, index) => (
          <ProgramCard
            key={index}
            variant={
              userId === "Polaznik" ? cardTypes.defaultCard : cardTypes.editCard
            }
            title={item.name}
            datum={item.dateFrom}
            userId={userId}
            image={item.imageURL}
            opis={item.description}
            voditelji={item.voditeljiCount}
            polaznici={item.polaznikCount}
            onClick={() => onClick(item.id)}
          />
        ))}
    </div>
  );
}

export default ModulUživo;
