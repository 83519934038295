import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import CheckFalse from "../../../../../../assets/svg/CheckFalse.svg";
import CheckTrue from "../../../../../../assets/svg/CheckTrue.svg";
import ExportIcon from "../../../../../../assets/svg/ExportIcon";
import UserIcon from "../../../../../../assets/svg/UserIcon";
import styles from "./Table.module.css";

const Table = ({ dataLive, dataOnline, selectedProgram }) => {
  const navigate = useNavigate();
  function formatData(rawData) {
    return rawData?.map((item) => {
      const application = JSON.parse(item.application);
      return {
        imeIPrezime: `${item.User.firstName} ${item.User.lastName}`,
        ...application,
        gradPrograma: item.User.programCity || null,
        strucnaSprema: item.User.qualification || null,
        brojTerapija: item.numberOfTherapies || null,
        mjestoStanovanja: item.User.city || null,
      };
    });
  }

  const modifiedData = formatData(dataLive);
  const modifiedDataOnline = formatData(dataOnline);

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.nameSurnameHeaderCell}>
                <p className={styles.headerCellLabel}>Ime i prezime</p>
              </th>
              <th className={styles.linkHeaderCell}>
                <p className={styles.headerCellLabel}>
                  Aplikacije za{" "}
                  {selectedProgram === "3,4,5,6"
                    ? "OR"
                    : selectedProgram === "7,8,9,10"
                    ? "eOR"
                    : selectedProgram === "11,12"
                    ? "TT"
                    : selectedProgram === "13,14"
                    ? "TP"
                    : "sve"}
                </p>
              </th>
              {selectedProgram === "11,12" && (
                <>
                  <th className={styles.descriptionHeaderCell}>
                    <p className={styles.headerCellLabel}>
                      Suglasnost Supervizora 1
                    </p>
                  </th>
                  <th className={styles.descriptionHeaderCell}>
                    <p className={styles.headerCellLabel}>
                      Suglasnost Supervizora 2
                    </p>
                  </th>
                </>
              )}
              {selectedProgram === "3,4,5,6" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Odobrenje mentora</p>
                </th>
              )}
              {selectedProgram === "13,14" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Odobrenje mentora</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataLive?.map((item, index) => (
              <tr className={styles.tableDataRow} key={index}>
                <td
                  className={styles.nameSurnameCell}
                  onClick={() => navigate(`/pojedini-polaznik/${item.User.id}`)}
                >
                  {item.User.imageUrl === "" ? (
                    <div className={styles.emptyProfilePhoto}>
                      <UserIcon className={styles.profilePhotoIcon} />
                    </div>
                  ) : (
                    <img
                      alt="user"
                      src={item.User.imageUrl}
                      className={styles.userPhoto}
                    />
                  )}
                  <p
                    className={styles.nameSurnameText}
                    onClick={() =>
                      navigate(`/pojedini-polaznik/${item.User.id}`)
                    }
                  >
                    {item.User.firstName} {item.User.lastName}
                  </p>
                </td>
                <td className={styles.tableLinkCell}>
                  <p
                    onClick={() =>
                      navigate(
                        `/dokumenti/aplikacije-za-upis/anketa/${item.id}`
                      )
                    }
                    className={styles.tableLinkText}
                  >
                    Link na anketu
                  </p>
                </td>
                {selectedProgram === "11,12" && (
                  <>
                    <td className={styles.tableSupervisorCell}>
                      <p className={styles.tableSupervisorText}>
                        {!item.supervisor1 ? "------" : item.supervisor1}
                      </p>
                    </td>
                    <td className={styles.tableSupervisorCell}>
                      <p className={styles.tableSupervisorText}>
                        {!item.supervisor2 ? "------" : item.supervisor2}
                      </p>
                    </td>
                  </>
                )}
                {selectedProgram === "3,4,5,6" && (
                  <td className={styles.tableSupervisorCell}>
                    <p className={styles.tableSupervisorText}>
                      {item.isAccepted === null
                        ? "------"
                        : item.isAccepted === true
                        ? "Odobreno"
                        : "Nije odobreno"}
                    </p>
                  </td>
                )}
                {selectedProgram === "13,14" && (
                  <td className={styles.tableSupervisorCell}>
                    <p className={styles.tableSupervisorText}>
                      {item.isAccepted === null ? (
                        "------"
                      ) : item.isAccepted === true ? (
                        <img src={CheckTrue} />
                      ) : (
                        <img src={CheckFalse} />
                      )}
                    </p>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modifiedData?.length > 0 && (
        <CSVLink
          data={modifiedData}
          target="_blank"
          filename={"AplikacijeZaUpis.csv"}
          className={styles.exportButton}
        >
          <p className={styles.exportButtonText}>Export tablice s odgovorima</p>
          <ExportIcon className={styles.exportButtonIcon} />
        </CSVLink>
      )}
      <div
        className={styles.tableContainer}
        style={{
          marginTop: "50px",
          display: selectedProgram === "3,4,5,6" ? "block" : "none",
        }}
      >
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.nameSurnameHeaderCell}>
                <p className={styles.headerCellLabel}>Ime i prezime</p>
              </th>
              <th className={styles.linkHeaderCell}>
                <p className={styles.headerCellLabel}>Aplikacije za eOR</p>
              </th>
              {selectedProgram === "3,4,5,6" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Evaluacija mentora</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataOnline?.map((item, index) => (
              <tr className={styles.tableDataRow} key={index}>
                <td
                  className={styles.nameSurnameCell}
                  onClick={() => navigate(`/pojedini-polaznik/${item.User.id}`)}
                >
                  {item.User.imageUrl === "" ? (
                    <div className={styles.emptyProfilePhoto}>
                      <UserIcon className={styles.profilePhotoIcon} />
                    </div>
                  ) : (
                    <img
                      alt="user"
                      src={item.User.imageUrl}
                      className={styles.userPhoto}
                    />
                  )}
                  <p
                    className={styles.nameSurnameText}
                    onClick={() =>
                      navigate(`/pojedini-polaznik/${item.User.id}`)
                    }
                  >
                    {item.User.firstName} {item.User.lastName}
                  </p>
                </td>
                <td className={styles.tableLinkCell}>
                  <p
                    onClick={() =>
                      navigate(
                        `/dokumenti/aplikacije-za-upis/anketa/${item.id}`
                      )
                    }
                    className={styles.tableLinkText}
                  >
                    Link na anketu
                  </p>
                </td>
                <td className={styles.tableSupervisorCell}>
                  <p className={styles.tableSupervisorText}>
                    {!item.mentorReview || item.mentorReview === null
                      ? "------"
                      : item.mentorReview}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedProgram === "3,4,5,6" && modifiedDataOnline?.length > 0 && (
        <CSVLink
          data={modifiedDataOnline}
          target="_blank"
          filename={"AplikacijeZaUpis.csv"}
          className={styles.exportButton}
        >
          <p className={styles.exportButtonText}>Export tablice s odgovorima</p>
          <ExportIcon className={styles.exportButtonIcon} />
        </CSVLink>
      )}
    </div>
  );
};
export default Table;
