import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import CustomHeader from "../../components/CustomHeader/CustomHeader";
import { getZakupiteljById } from "../../components/ZakupiteljChoice/api/getZakupiteljById";
import { useUserState } from "../../context/UserContext";
import { getAllCities } from "../groups/api/getAllCities";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../myProfile/utils";
import { getAllRooms } from "./api/getAllRooms";
import { getAllUsers } from "./api/getAllUsers";
import { getEvents } from "./api/getEvents";
import { updateDefaultCity } from "./api/updateDefaultCity";
import "./App.css";
import CreateModal from "./components/CreateModal";
import InfoModal from "./components/InfoModal";
import {
  addOneHourToTime,
  handleDatesSet,
  selectStyles,
  transformEventName,
} from "./utils";

const CalendarIpd = () => {
  const currentDate = new Date();

  const { role, userData, setUserData } = useUserState();
  const { authToken } = useAuthentication();
  const [events, setEvents] = useState();
  const [isDayGridMonth, setIsDayGridMonth] = useState(false);
  const [info, setInfo] = useState({});
  const [rooms, setRooms] = useState([]);
  const [cities, setCities] = useState([]);
  const [secondRooms, setSecondRooms] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [type, setType] = useState("");
  const [zakupitelj, setZakupitelj] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [zakupiteljType, setZakupiteljType] = useState([]);
  const [isBusyOpen, setIsBusyOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [busyAppointmentInfo, setBusyAppointmentInfo] = useState(false);
  const [defaultSelectedCity, setDefaultSelectedCity] = useState(
    userData?.defaultCity || null
  );
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedCityForSecond, setSelectedCityForSecond] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateForSecond, setSelectedDateForSecond] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  const [selectedTime, setSelectedTime] = useState({
    startTime: null,
    endTime: null,
  });
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [showDefaultCityRadios, setShowDefaultCityRadios] = useState(
    userData?.defaultCity === null
  );
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [secondSelectedRoomId, setSecondSelectedRoomId] = useState(null);
  const [citiesForSecond, setCitiesForSecond] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [editEventValues, setEditEventValues] = useState({
    eventId: "",
    eventType: "",
    supervisionType: "",
    clientId: "",
    creatorId: "",
    supervisorId: "",
    outsideClient: "",
    name: "",
    date: "",
    timeFrom: "",
    timeTo: "",
    description: "",
    roomId: "",
  });

  const [startDateSecond, setStartDateSecond] = useState(
    new Date().toISOString().split("T")[0]
  );

  const calendarRef = useRef(null);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setStartDateSecond(selectedDate);
  };

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(startDateSecond);
    }
  }, [startDateSecond]);

  const filterByCity = (selectedCity) => {
    const filteredRooms = allRooms?.filter(
      (room) => room.cityId == selectedCity?.value
    );
    setRooms(filteredRooms);
  };

  const handleDefaultCityChange = (value, label) => {
    setDefaultSelectedCity({ value, label });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getAllUsers(authToken);
      setAllUsers(response);
    };
    if (authToken) fetchUsers();
  }, [authToken]);

  useEffect(() => {
    const fetchCities = async () => {
      const response = await getAllCities(authToken);
      const filteredResponse = response.filter(
        (city) =>
          city.label !== "Podgorica" &&
          city.label !== "Rijeka" &&
          city.label !== "Split"
      );

      const finalCities = filteredResponse.map((city) => {
        if (city.label === "Online") {
          return { ...city, label: "Online/Bez sobe" };
        }
        return city;
      });

      setCities(finalCities);
      setCitiesForSecond(finalCities);
    };
    if (authToken) fetchCities();
  }, [authToken]);

  useEffect(() => {
    const fetchRooms = async () => {
      const response = await getAllRooms(authToken);
      setAllRooms(
        response.rooms.map((room) => ({
          label: room.roomName,
          value: room.id,
          cityId: room.cityId,
        }))
      );
    };
    if (authToken) fetchRooms().then(() => filterByCity(selectedCity));
  }, [authToken]);

  useEffect(() => {
    const fetchZakupitelj = async () => {
      const response = await getZakupiteljById(userData.id, authToken);
      setZakupitelj(response[0] || null);
    };
    if (authToken) fetchZakupitelj();
  }, [authToken]);

  const getUsersName = (userId, event) => {
    const user = allUsers?.find((user) => user.id === userId);
    if (!user) {
      const resp =
        event?.clientOutsideIPD?.length > 0
          ? event.clientOutsideIPD
          : transformEventName(event.eventType);
      return resp;
    }
    return user?.firstName + " " + user?.lastName;
  };

  const handleRoomChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((item) => item.value);
    setSelectedRooms(selectedValues);
  };
  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
    setSelectedCityForSecond(selectedOptions);
    setZakupiteljType(
      zakupitelj?.cities?.find(
        (city) => city?.cityId === selectedOptions?.value
      )?.zakupiteljType || null
    );
  };

  const handleCityChangeForSecond = (selectedOptions) => {
    setSelectedCityForSecond(selectedOptions);
  };

  const handleSubmitDefaultCity = async () => {
    await updateDefaultCity(defaultSelectedCity.value, authToken);
    setShowDefaultCityRadios(false);
    setUserData((prevState) => ({
      ...prevState,
      defaultCity: defaultSelectedCity.value,
    }));
  };

  const licence = document.querySelector(".fc-license-message");
  if (licence) licence.style.display = "none";

  useEffect(() => {
    const updatedEndDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 2,
      2
    );
    if (authToken)
      getEvents(
        startDate,
        updatedEndDate,
        selectedCity?.value,
        selectedRooms,
        authToken,
        setEvents
      );
  }, [reRender, startDate, selectedRooms, selectedCity, authToken]);

  useEffect(() => {
    const updatedEndDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 2,
      2
    );
    const roomIds = allRooms.map((room) => room.value);
    if (authToken)
      getEvents(
        startDate,
        updatedEndDate,
        selectedCity?.value,
        roomIds,
        authToken,
        setAllEvents
      );
  }, [reRender, startDate, authToken, selectedCity]);

  const handleDateClick = (info) => {
    const date = info.dateStr.split("T")[0];
    const secondRoomId = info?.resource?.id;
    setType("create");
    setSelectedDate(date);
    setSecondSelectedRoomId(secondRoomId);
    setIsCreateOpen(true);
  };

  useEffect(() => {
    const filteredRooms = allRooms?.filter(
      (room) => room.cityId == selectedCityForSecond?.value
    );
    setSecondRooms(filteredRooms);
  }, [selectedCityForSecond, rooms]);

  const handleEventClick = (info) => {
    const dateStart = new Date(info.event._instance.range.start);
    const dateEnd = new Date(info.event._instance.range.end);

    dateStart.setHours(dateStart.getHours() - 1);
    dateEnd.setHours(dateEnd.getHours() - 1);

    const hoursStart = dateStart.getHours();
    const minutesStart = dateStart.getMinutes();
    const secondsStart = dateStart.getSeconds();

    const hoursEnd = dateEnd.getHours();
    const minutesEnd = dateEnd.getMinutes();
    const secondsEnd = dateEnd.getSeconds();

    const formattedTimeStart = `${hoursStart
      .toString()
      .padStart(2, "0")}:${minutesStart
      .toString()
      .padStart(2, "0")}:${secondsStart.toString().padStart(2, "0")}`;

    const formattedTimeEnd = `${hoursEnd
      .toString()
      .padStart(2, "0")}:${minutesEnd.toString().padStart(2, "0")}:${secondsEnd
      .toString()
      .padStart(2, "0")}`;

    const secondInfo = {
      start: formattedTimeStart,
      end: formattedTimeEnd,
      roomId: info.event._def?.resourceIds
        ? info.event._def.resourceIds[0]
        : null,
      title: info.event._def.title,
      dateStart,
    };

    const clickedEvent = info.event._def.extendedProps;
    const isEditable = clickedEvent.isEditable;
    const eventStart = info.event.start;
    if (
      role === "Referada" ||
      (zakupiteljType !== null &&
        (userData.id === clickedEvent.therapistId ||
          userData.id === clickedEvent.creatorId ||
          userData.id === clickedEvent.supervisorId))
    ) {
      setType("edit");

      if (!isEditable) {
        if (secondInfo.roomId === null) {
          setInfo({
            room: clickedEvent.roomId,
            timeFrom: clickedEvent.timeFrom,
            timeTo: clickedEvent.timeTo,
            eventType: clickedEvent.eventType,
            therapistName: clickedEvent.therapistName || null,
            dateStart: secondInfo.dateStart || null,
          });
        } else {
          setInfo({
            room: parseInt(secondInfo.roomId, 10),
            timeFrom: secondInfo.start.slice(0, 5),
            timeTo: secondInfo.end.slice(0, 5),
            eventType: clickedEvent.eventType,
            title: secondInfo.title,
            therapistName: clickedEvent.therapistName || null,
            dateStart: secondInfo.dateStart || null,
          });
        }
        setIsInfoOpen(true);
      } else {
        if (
          clickedEvent.eventType === "therapy" ||
          clickedEvent.eventType === "individual_supervision" ||
          clickedEvent.eventType === "other"
        ) {
          setEditEventValues({
            eventId: info.event.id ? info.event.id : "",
            eventType: clickedEvent.eventType ? clickedEvent.eventType : "",
            supervisionType: "individual",
            clientId: clickedEvent.clientId ? clickedEvent.clientId : "",
            outsideClient: clickedEvent.clientOutsideIPD
              ? clickedEvent.clientOutsideIPD
              : "",
            name: secondInfo?.title,
            date: eventStart ? eventStart.toISOString().split("T")[0] : "",
            timeFrom: clickedEvent.timeFrom
              ? clickedEvent.timeFrom.slice(0, -3)
              : "",
            timeTo: clickedEvent.timeTo ? clickedEvent.timeTo.slice(0, -3) : "",
            description: clickedEvent.description
              ? clickedEvent.description
              : "",
            roomId: clickedEvent.roomId ? clickedEvent.roomId : "",
            therapistId: clickedEvent.therapistId
              ? clickedEvent.therapistId
              : "",
            creatorId: clickedEvent.creatorId ? clickedEvent.creatorId : "",
            supervisorId: clickedEvent.supervisorId
              ? clickedEvent.supervisorId
              : "",
          });
          setIsCreateOpen(true);
        } else {
          setInfo({
            room: clickedEvent.roomId ? clickedEvent.roomId : "",
            timeFrom: secondInfo.start?.slice(0, 5),
            timeTo: secondInfo.end?.slice(0, 5),
            eventType: clickedEvent.eventType,
            title: secondInfo?.title,
            therapistName: clickedEvent.therapistName || null,
            dateStart: secondInfo.dateStart || null,
          });
          setIsInfoOpen(true);
        }
      }
    } else {
      const data = {
        therapistName: clickedEvent.therapistName || null,
        room: parseInt(clickedEvent?.roomId, 10)
          ? parseInt(clickedEvent?.roomId, 10)
          : parseInt(secondInfo?.roomId, 10),
        timeFrom: secondInfo.start.slice(0, 5),
        timeTo: secondInfo.end.slice(0, 5),
        eventType: clickedEvent.eventType,
        title: secondInfo.title,
        clientId: clickedEvent.clientId ? clickedEvent.clientId : "",
        outsideClient: clickedEvent.clientName ? clickedEvent.clientName : "",
        therapistId: clickedEvent.therapistId ? clickedEvent.therapistId : "",
        dateStart: secondInfo.dateStart || null,
      };
      setInfo(data);
      setIsInfoOpen(true);
    }
  };

  useEffect(() => {
    if (userData?.defaultCity !== null && zakupitelj) {
      handleCityChange(
        cities?.find((city) => city.value === userData?.defaultCity)
      );
      handleCityChangeForSecond(
        cities?.find((city) => city.value === userData?.defaultCity)
      );
    } else if (userData?.defaultCity !== null && !zakupitelj) {
      handleCityChange(
        cities?.find((city) => city.value === userData?.defaultCity)
      );
      handleCityChangeForSecond(
        cities?.find((city) => city.value === userData?.defaultCity)
      );
    } else {
      handleCityChange({ label: "Zagreb", value: 2 });
      handleCityChangeForSecond({ label: "Zagreb", value: 2 });
    }
  }, [authToken, cities, zakupitelj]);

  const filterEventsByRooms = (selectedRooms) => {
    if (!selectedRooms) {
      setFilteredEvents(events);
    } else if (selectedRooms.length === 0) {
      const filtered = events?.filter((event) => {
        return selectedCity?.value === event.roomId;
      });
      setFilteredEvents(filtered);
    } else {
      const filtered = events?.filter((event) => {
        return selectedRooms.some((room) => room.value === event.roomId);
      });

      setFilteredEvents(filtered);
    }
  };

  useEffect(() => {
    filterEventsByRooms(selectedRooms);
  }, [selectedRooms, events]);

  useEffect(() => {
    filterByCity(selectedCity);
  }, [selectedCity, allRooms]);

  const resourceRooms = secondRooms.map((room) => ({
    id: room.value,
    title: room.label,
  }));

  const transformedEvents = allEvents.map((event) => {
    const startDate = event.start ? new Date(event.start) : null;
    const endDate = event.end ? new Date(event.end) : null;
    const changeDate = new Date("2025-03-30T00:00:00");

    if (startDate instanceof Date && !isNaN(startDate)) {
      if (startDate > changeDate) {
        startDate.setHours(startDate.getHours() - 2);
      } else {
        startDate.setHours(startDate.getHours() - 1);
      }
    }
    if (endDate instanceof Date && !isNaN(endDate)) {
      if (startDate > changeDate) {
        endDate.setHours(endDate.getHours() - 2);
      } else {
        endDate.setHours(endDate.getHours() - 1);
      }
    }
    return {
      id: `${event.id}-${event.eventType}`,
      userId: event.therapistId || event.supervisorId || event.creatorId,
      title: event?.name
        ? event?.name
        : userData.id === event.supervisorId ||
          userData.id === event.therapistId
        ? getUsersName(event.clientId, event)
        : event?.supervisorId
        ? getUsersName(event.supervisorId, event)
        : event?.therapistId
        ? getUsersName(event.therapistId, event)
        : transformEventName(event?.eventType) || "Unnamed Event",
      start: startDate && !isNaN(startDate) ? startDate.toISOString() : null,
      end: endDate && !isNaN(endDate) ? endDate.toISOString() : null,
      resourceId: event?.roomId?.toString() || null,
    };
  });

  let transformedAllEvents = [];

  if (events && events.length > 0) {
    transformedAllEvents = events.map((event) => {
      const startDate = event.start ? new Date(event.start) : null;
      const endDate = event.end ? new Date(event.end) : null;

      if (
        startDate instanceof Date &&
        !isNaN(startDate) &&
        endDate instanceof Date &&
        !isNaN(endDate)
      ) {
        const changeDate = new Date("2025-03-30T00:00:00");
        if (startDate > changeDate) {
          startDate.setHours(startDate.getHours() - 2);
          endDate.setHours(endDate.getHours() - 2);
        } else {
          startDate.setHours(startDate.getHours() - 1);
          endDate.setHours(endDate.getHours() - 1);
        }

        return {
          ...event,
          userId: event.therapistId,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
        };
      }

      return event;
    });
  }

  return (
    <div>
      {(isLoading || loadingDelete) && (
        <div className={"loadingContainer"}>
          <BeatLoader color="#cb9c2d" />
        </div>
      )}
      {isCreateOpen && (
        <CreateModal
          type={type}
          users={allUsers}
          date={selectedDate}
          timeProp={selectedTime}
          rooms={rooms}
          onClick={() => setIsCreateOpen(false)}
          startDate={startDate}
          endDate={endDate}
          selectedRooms={selectedRooms}
          setEvents={setEvents}
          setLoadingDelete={setLoadingDelete}
          reRender={reRender}
          setReRender={setReRender}
          setIsCreateOpen={setIsCreateOpen}
          editEventValues={editEventValues}
          secondSelectedRoomId={secondSelectedRoomId}
          zakupiteljType={zakupiteljType}
          setIsBusyOpen={setIsBusyOpen}
          setBusyAppointmentInfo={setBusyAppointmentInfo}
          getUsersName={getUsersName}
          setIsLoading={setIsLoading}
        />
      )}
      {isInfoOpen && (
        <InfoModal
          data={info}
          rooms={allRooms}
          onClick={() => setIsInfoOpen(false)}
          userData={userData}
          getUsersName={getUsersName}
        />
      )}
      {isBusyOpen && (
        <div className={"busyWrapper"}>
          <div className={"busyContainer"}>
            <div className={"modalContent"}>
              <h1 className={"busyTitle"}>Zauzeti termini</h1>

              <div className={"busyContent"}>
                {busyAppointmentInfo.map((event, index) => (
                  <div key={index} className={"busyItem"}>
                    {formatDate(event?.date) +
                      " " +
                      event?.timeFrom +
                      "-" +
                      addOneHourToTime(event?.timeFrom)}{" "}
                    -{" "}
                    {event.therapistId
                      ? allUsers.find((user) => user.id === event.therapistId)
                          ?.firstName +
                        " " +
                        allUsers.find((user) => user.id === event.therapistId)
                          ?.lastName
                      : allUsers.find((user) => user.id === event.creatorId)
                          ?.firstName +
                        " " +
                        allUsers.find((user) => user.id === event.creatorId)
                          ?.lastName}
                  </div>
                ))}
              </div>
            </div>
            <div className={"busyBtn"}>
              <button
                className={"btnOk"}
                onClick={() => {
                  setIsBusyOpen(false);
                }}
              >
                U redu
              </button>
            </div>
          </div>
        </div>
      )}

      <CustomHeader title={"Školski kalendar"} />
      <Box>
        <Box
          display="flex"
          className="sidebar-box"
          justifyContent="space-between"
          marginTop={20}
        >
          <div className="calendar-sidebar-container">
            {showDefaultCityRadios && (
              <div className="default-city-container">
                <p
                  style={{
                    fontSize: 18,
                    color: "#5f6d7e",
                    fontWeight: 600,
                    fontFamily: "Inter",
                    marginBlockStart: 0,
                  }}
                >
                  Odaberi grad za kojeg želiš da ti se prvi prikazuje:
                </p>
                {cities.map((city, index) => (
                  <div key={index} className="option-container">
                    <input
                      type="radio"
                      name="default-city"
                      className="default-city-option"
                      value={city.value}
                      onChange={() =>
                        handleDefaultCityChange(city.value, city.label)
                      }
                      checked={defaultSelectedCity?.value === city.value}
                    />{" "}
                    <label
                      htmlFor={city.value}
                      style={{
                        fontSize: 15,
                        color: "#5f6d7e",
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      {city.label}
                    </label>
                  </div>
                ))}
                <button
                  className="default-btn"
                  onClick={() => handleSubmitDefaultCity()}
                >
                  Spremi
                </button>
              </div>
            )}
            <br />
            <Select
              options={cities}
              styles={selectStyles}
              onChange={handleCityChange}
              className="selectCal"
              value={selectedCity}
              isSearchable={false}
              menuPortalTarget={document.body}
              components={{ IndicatorSeparator: () => null }}
            />
            <br />
            <Select
              options={rooms}
              styles={selectStyles}
              onChange={handleRoomChange}
              placeholder="Prikaži sobe"
              className="selectCal"
              isSearchable={false}
              isMulti
              menuPortalTarget={document.body}
              components={{ IndicatorSeparator: () => null }}
            />
            <br />
          </div>

          <Box flex="1 1 100%" ml="15px">
            <div className="fc">
              <FullCalendar
                height="75vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,today,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                dateClick={
                  role === "Referada" || zakupiteljType !== null
                    ? handleDateClick
                    : null
                }
                initialView="dayGridMonth"
                editable={false}
                selectable={true}
                datesSet={(info) => {
                  const isMonthView = info.view.type === "dayGridMonth";
                  setIsDayGridMonth(isMonthView);
                  handleDatesSet(
                    info,
                    selectedRooms,
                    authToken,
                    setEvents,
                    setStartDate,
                    setEndDate,
                    startDate,
                    endDate
                  );
                }}
                selectMirror={true}
                dayMaxEvents={true}
                select={
                  (role === "Referada" || zakupiteljType !== null) &&
                  ((info) => handleDateClick(info))
                }
                eventClick={handleEventClick}
                events={transformedAllEvents}
                eventContent={(data) => (
                  <div
                    style={{
                      overflow: "hidden",
                      width: "100%",
                      minHeight: "18px",
                      height: "100%",
                      borderRadius: 4,
                      padding: 2,
                      backgroundColor:
                        data.event.extendedProps.userId === userData.id
                          ? "#3eb489"
                          : "#cb9c2d",
                      boxSizing: "border-box",
                    }}
                  >
                    {data.event._def.extendedProps.name
                      ? data.event._def.extendedProps.name
                      : userData.id ===
                          data.event._def.extendedProps.therapistId ||
                        userData.id ===
                          data.event._def.extendedProps.supervisorId
                      ? getUsersName(
                          data.event._def.extendedProps?.clientId,
                          data.event._def.extendedProps
                        )
                      : data.event._def.extendedProps?.therapistId
                      ? getUsersName(
                          data.event._def.extendedProps?.therapistId,
                          data.event._def.extendedProps
                        )
                      : data.event._def.extendedProps?.clientOutsideIPD
                      ? data.event._def.extendedProps?.clientOutsideIPD
                      : data.event._def.extendedProps?.supervisorId
                      ? getUsersName(
                          data.event._def.extendedProps?.supervisorId,
                          data.event._def.extendedProps
                        )
                      : transformEventName(
                          data.event._def.extendedProps.eventType
                        )}
                  </div>
                )}
                slotMinTime="07:00:00"
                slotMaxTime="22:00:00"
                allDaySlot={false}
                eventMouseEnter={() => {}}
                eventMouseLeave={() => {}}
                slotLabelContent={(slotInfo) => {
                  const hours = slotInfo.date.getHours();
                  const minutes = slotInfo.date
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");

                  return (
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#71717A",
                        fontFamily: "Inter",
                      }}
                    >
                      {`${hours}:${minutes}`}{" "}
                    </span>
                  );
                }}
                slotLabelInterval={{ minutes: 30 }}
                dayHeaderContent={(info) => {
                  const dayName = info.date
                    .toLocaleString("en-US", { weekday: "short" })
                    .toUpperCase();
                  const date = info.date.getDate();
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                      }}
                    >
                      <div
                        style={{
                          color: "#71717A",
                          fontSize: "11px",
                          fontWeight: "700",
                          fontFamily: "Inter",
                        }}
                      >
                        {dayName}
                      </div>
                      {!isDayGridMonth ? (
                        <div
                          style={{
                            fontSize: "22px",
                            fontWeight: "500",
                            color: "#000000",
                            fontFamily: "Inter",
                          }}
                        >
                          {date}
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "10px",
                          }}
                        ></div>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
      {(zakupiteljType !== null ||
        role === "Referada" ||
        role === "Zakupitelj") && (
        <div style={{ padding: "0px 16px" }}>
          <h1
            style={{
              marginTop: 50,
              width: "100%",
              textAlign: "center",
              fontSize: 28,
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#4D4D4D",
            }}
          >
            Pregled po sobama
          </h1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 40,
            }}
          >
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <label
                htmlFor="dateSelect"
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#5F6D7E",
                }}
              >
                Odaberi grad
              </label>
              <Select
                options={citiesForSecond}
                styles={selectStyles}
                onChange={handleCityChangeForSecond}
                value={selectedCityForSecond}
                isSearchable={false}
                isDisabled={false}
                components={{ IndicatorSeparator: () => null }}
              />
            </div>
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <label
                htmlFor="dateSelect"
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#5F6D7E",
                }}
              >
                Odaberi datum
              </label>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                className={"date-select-calendar"}
                onChange={handleDateChange}
                value={startDateSecond}
              />
            </div>
          </div>
          <div className="fc2">
            <FullCalendar
              ref={calendarRef}
              height="75vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                resourceTimeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                right: "prev,today,next",
                left: "title",
              }}
              dateClick={() => {}}
              initialView="resourceTimeGridDay"
              editable={false}
              selectable={true}
              resourceAreaHeaderContent="Sobe"
              resources={resourceRooms}
              resourceOrder={resourceRooms[0]?.id}
              datesSet={(info) =>
                handleDatesSet(
                  info,
                  selectedRooms,
                  authToken,
                  setEvents,
                  setStartDate,
                  setEndDate,
                  startDate,
                  endDate
                )
              }
              selectMirror={true}
              dayMaxEvents={true}
              select={role !== "Polaznik" && ((info) => handleDateClick(info))}
              eventClick={handleEventClick}
              events={transformedEvents}
              slotMinTime="07:00:00"
              slotMaxTime="21:15:00"
              eventContent={(props) => (
                <div
                  style={{
                    height: "100%",
                    padding: 4,
                    borderRadius: 4,
                    textTransform: "capitalize",
                    textAlign: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor:
                      props.event.extendedProps.userId === userData.id
                        ? "#3eb489"
                        : "#cb9c2d",
                  }}
                >
                  {props.event.title.toLocaleLowerCase()}
                  {props.event.userId}
                </div>
              )}
              allDaySlot={false}
              slotLabelContent={(slotInfo) => {
                const hours = slotInfo.date.getHours();
                const minutes = slotInfo.date
                  .getMinutes()
                  .toString()
                  .padStart(2, "0");

                return (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#71717A",
                      fontFamily: "Inter",
                    }}
                  >
                    {`${hours}:${minutes}`}{" "}
                  </span>
                );
              }}
              slotLabelInterval={{ minutes: 30 }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarIpd;
