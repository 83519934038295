import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import ReusableTabs from "../../components/TabMenu/Tabs.jsx";
import { polaznikTabsData, tabsData } from "../../components/TabMenu/utils.js";
import { useUserState } from "../../context/UserContext.js";
import { useAuthentication } from "../hooks/useAuthentication.js";
import { formatDate } from "../myProfile/utils.js";
import ProgramContainer from "./ProgramContainer/ProgramContainer.jsx";
import { fetchProgramData } from "./utils.js";

const PojediniProgram = () => {
  const navigate = useNavigate();
  const { selectedTab = "one", program_id } = useParams();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab);
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const [programData, setProgramData] = useState({});
  const [isAllowed, setIsAllowed] = useState(null);

  const handleTabChange = (newValue) => {
    setCurrentTabIndex(newValue);
    navigate(`/programi/${newValue}`);
  };

  useEffect(() => {
    if (authToken) {
      fetchProgramData(authToken, program_id, setProgramData, setIsAllowed);
    }
  }, [authToken, program_id]);

  return (
    <div>
      {isAllowed === true ? (
        <>
          <ReusableTabs
            currentTabIndex={currentTabIndex}
            handleTabChange={handleTabChange}
            tabsData={userId !== "Referada" ? polaznikTabsData : tabsData}
          />
          {programData && programData.theoreticalModules && (
            <ProgramContainer
              image={programData.image}
              title={programData.title}
              opis={programData.description}
              datumUpisa={formatDate(programData?.datumUpisa)}
              userId={userId}
              teorijskiDio={programData.theoreticalModules.map((module) => ({
                ...module,
                resources: module.resources.filter(
                  (resource) => resource.resourceType !== "image/jpeg"
                ),
              }))}
              iskustveniDio={programData.modules}
              fetchProgramData={fetchProgramData}
              setProgramData={setProgramData}
            />
          )}
        </>
      ) : isAllowed === false ? (
        <div
          style={{
            display: "flex",
            gap: 10,
            backgroundColor: "#ffebeb",
            width: "100%",
            height: 40,
            borderRadius: 6,
            fontSize: 15,
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            color: "#5f6d7e",
          }}
        >
          <img alt="" src={InfoIcon} height={18} width={18} />
          Trenutno vam nije omogućen pristup ovom programu!
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PojediniProgram;
