import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAllEORYears = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/all-programs`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const years = response.data.filter(
      (year) => year.title.slice(0, 3) === "eOR"
    );
    const selectData = years.map((year) => ({
      value: year.id,
      label: `${year.title.slice(3, 4)}. godina`,
    }));
    return selectData;
  } catch (error) {
    console.error("Error getting current user data", error);
    throw error;
  }
};
