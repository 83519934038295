import { useEffect, useState } from "react";
import Prijavnice from "../../../../assets/img/PolaznikPrijavnice.png";
import { useAuthentication } from "../../../hooks/useAuthentication";
import Carousel from "../components/Carousel";
import styles from "../VikendIntenzivi/VikendIntenzivi.module.css";
import { getAllSummer } from "./api/getAllSummer";

function LjetniIntenzivi({ onClick }) {
  const [ipdIntensive, setipdIntensive] = useState([]);
  const [procesniIntensive, setProcesniIntensive] = useState([]);
  const { authToken } = useAuthentication();

  useEffect(() => {
    const fetchSI = async () => {
      try {
        const response = await getAllSummer(authToken);
        const procesniIntensiveFiltered = response?.active?.filter(
          (item) => item.intensiveType === "procesni"
        );
        const ipdIntensiveFiltered = response?.active?.filter(
          (item) => item.intensiveType === "IPD"
        );
        console.log(ipdIntensiveFiltered);

        setProcesniIntensive(procesniIntensiveFiltered);
        setipdIntensive(ipdIntensiveFiltered);
      } catch (error) {
        console.error("Error fetching intensives:", error);
      }
    };

    if (authToken) fetchSI();
  }, [authToken]);
  console.log(ipdIntensive, procesniIntensive);
  return (
    <div className="subpage-wrapper">
      <div className={styles.title}>Ljetni intenzivi</div>
      {ipdIntensive?.length > 0 ? (
        <Carousel data={ipdIntensive} onClick={onClick} />
      ) : (
        <div className={styles.emptyWrapper}>
          <img alt="" src={Prijavnice} className={styles.emptyIcon} />
          <span className={styles.emptySpan}>
            Trenutno nema aktivnih intenziva
          </span>
        </div>
      )}
      <div className={styles.title}>Ljetni procesni intenzivi</div>
      {procesniIntensive?.length > 0 ? (
        <Carousel data={procesniIntensive} onClick={onClick} />
      ) : (
        <div className={styles.emptyWrapper}>
          <img alt="" src={Prijavnice} className={styles.emptyIcon} />
          <span className={styles.emptySpan}>
            Trenutno nema aktivnih intenziva
          </span>
        </div>
      )}
    </div>
  );
}

export default LjetniIntenzivi;
