import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Down from "../../../assets/img/ArrowDown.png";
import Question from "../../../assets/img/Help.png";
import Plus from "../../../assets/img/PlusIcon.png";
import Success from "../../../assets/img/Success.png";
import Trash from "../../../assets/svg/TrashIcon2.svg";
import LoaderModal from "../../../components/LoaderModal/LoaderModal";
import Modal from "../../../components/Modal/Modal";
import { useCreateEventState } from "../../../context/CreateEventContext";
import { getRooms } from "../../FakultativnaRadionicaEditCreate/api/getRooms";
import {
  AddVoditeljButton,
  BackgroundImage,
  ButtonColumn,
  DatePlaceRow,
  DropdownArrow,
  EditButton,
  EditFooter,
  Header,
  Input,
  InputContainer,
  InputWrapper,
  ProgramImage,
  SectionTitle,
  SectionTitleRow,
  Select,
  SmallInputContainer,
  TableContainer,
  TextArea,
  Title,
  TitleBold,
  UrediButton,
  VoditeljiContainer,
  Wrapper,
} from "../../FakultativnaRadionicaEditCreate/EditCreate/FakultativnaRadionicaEdit.styles";
import TablePolaznici from "../../FakultativnaRadionicaEditCreate/PolazniciTable/TablePolaznici";
import { columnNames } from "../../FakultativnaRadionicaEditCreate/utils";
import VoditeljiCard from "../../FakultativnaRadionicaEditCreate/VoditeljiCard/VoditeljiCard";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addUserToEModule } from "../api/addUserToEModule";
import { createEModule } from "../api/createEModule";
import { deleteEModule } from "../api/deleteEModule";
import { getAllEORYears } from "../api/getAllEORYears";
import { getEORGroups } from "../api/getEORGroups";
import { updateEModule } from "../api/updateEModule";
import { handleSelectChange } from "../util";
import { ImageButton, MultiSelect } from "./EModuliEditCreate.styles";

function EModuliEditCreate() {
  const location = useLocation();
  const navigate = useNavigate();
  const [years, setYears] = useState([]);
  const { authToken } = useAuthentication();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const isCreateMode = location?.state?.isCreateMode || false;
  const isEditMode = location?.state?.isEditMode || false;
  const eModulData = isEditMode && location?.state?.eModulData;
  const [showTable, setShowTable] = useState(false);
  const [showImage, setShowImage] = useState(null);
  const [groups, setGroups] = useState([]);
  const { createEventData, updateCreateEventData, resetCreateEventData } =
    useCreateEventState();
  useEffect(() => {
    if (
      createEventData.voditeljiData?.length === 0 &&
      createEventData.polazniciData?.length === 0
    ) {
      updateCreateEventData({
        ...createEventData,
        voditeljiData: eModulData.usersResponse?.voditeljResponse,
        polazniciData: eModulData.usersResponse?.newPolazniciResponse,
        showImage: eModulData.imageURL,
      });
    }
  }, []);

  const toggleDropdown = () => {
    setShowTable(!showTable);
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      const formData = new FormData();
      formData.append("image", file);
      setSelectedImage(formData);
      const imageFile = formData.get("image");
      setShowImage(imageURL);
      setSelectedImage(imageFile);
      updateCreateEventData({
        ...createEventData,
        imageURL: imageFile,
        showImage: imageURL,
      });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    name: "",
    e_or_year: "",
    grupe: [],
    dateFrom: "",
    dateTo: "",
    roomId: "",
    description: "",
    aditionalDescription: "",
  });

  const handleDeleteEModule = async () => {
    try {
      await deleteEModule(eModulData.id, authToken);
      navigate("/programi/six");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await getEORGroups(authToken);
      const updatedGroups = response.flat(1).map((group) => ({
        value: group.id,
        label: group.name,
        programId: group.programId,
      }));
      setGroups(updatedGroups);
    };

    if (authToken) fetchGroups();
  }, [authToken]);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await getRooms(authToken);
        const updatedRooms = [
          { value: 99, label: "Bez sobe", isLarge: false },
          ...(response.rooms?.map((room) => ({
            value: room.id,
            label: room.roomName,
            isLarge: room.isLarge,
          })) || []),
        ];

        setRooms(updatedRooms);
      } catch (error) {
        console.log(error);
      }
    };

    if (authToken) fetchRooms();
  }, [authToken]);

  useEffect(() => {
    const fetchYears = async () => {
      const response = await getAllEORYears(authToken);
      setYears(response);
    };

    if (authToken) fetchYears();
  }, [authToken]);

  const handleRoomSelection = (selectedRoomId) => {
    setFormData({
      ...formData,
      roomId: selectedRoomId,
    });
    updateCreateEventData({
      ...createEventData,
      roomId: selectedRoomId,
    });
  };

  const clearSelectedImage = () => {
    setSelectedImage(null);
    setShowImage(null);
    updateCreateEventData({
      ...createEventData,
      imageURL: "",
      showImage: null,
    });
  };

  useEffect(() => {
    if (isEditMode && eModulData && !selectedImage) {
      setSelectedImage(eModulData.image_url);
    }
  }, [isEditMode, eModulData, selectedImage]);
  useEffect(() => {
    if (isEditMode && eModulData) {
      setFormData({
        name: eModulData.name || "",
        e_or_year: eModulData.e_or_year || "",
        grupe: eModulData.grupe || [],
        dateFrom: eModulData.dateFrom || "",
        dateTo: eModulData.dateTo || "",
        roomId: eModulData.roomId || "",
        description: eModulData.description || "",
        aditionalDescription: eModulData.aditionalDescription || "",
      });
    }
  }, [isEditMode, eModulData]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    updateCreateEventData({
      ...createEventData,
      [name]: value,
    });
  };

  const deleteVoditelj = (id) => {
    updateCreateEventData((prevState) => ({
      ...prevState,
      voditeljiData: prevState.voditeljiData.filter((item) => item.id !== id),
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (isCreateMode) {
        try {
          const polaznici = createEventData.polazniciData?.map(
            (polaznik) => polaznik.id
          );
          const voditelji = createEventData.voditeljiData?.map(
            (voditelj) => voditelj.id
          );
          const newData = {
            name: createEventData.name,
            dateFrom: createEventData.dateFrom,
            dateTo: createEventData.dateTo,
            roomId: createEventData.roomId,
            // groupIds: createEventData.grupe,
            description: createEventData.description,
            aditionalDescription: createEventData.aditionalDescription,
            imageURL: createEventData.imageURL,
            masterAbbreviation: "masterAbbreviation", // provjerit triba li
            polaznici,
            voditelji,
          };

          const formData = new FormData();

          Object.entries(newData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          });

          const response = await createEModule(formData, authToken);
          const responseData = response.data;
          if (response.status === 200) {
            setIsLoading(false);
            setModalContent({
              image: Success,
              description: "Uspješno ste kreirali e-modul",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate(-1),
                  className: "modal-btn-success",
                },
              ],
            });
            setShowModal(true);
          } else {
            setIsLoading(false);
            setModalContent({
              image: Question,
              description: "Nešto je pošlo po krivu. Pokušajte ponovo",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate(-1),
                  className: "",
                },
              ],
            });
            setShowModal(true);
            console.log("Error occurred:", responseData.opis);
          }
          return responseData;
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          return null;
        }
      } else if (isEditMode) {
        try {
          const polaznici = createEventData.polazniciData?.map(
            (polaznik) => polaznik.id
          );
          const voditelji = createEventData.voditeljiData?.map(
            (voditelj) => voditelj.id
          );
          const updatedData = {
            name: createEventData.name || eModulData.name,
            // eORYear: createEventData.e_or_year || eModulData.eORYear,
            dateFrom: createEventData.dateFrom || eModulData.dateFrom,
            dateTo: createEventData.dateTo || eModulData.dateTo,
            roomId: createEventData.roomId || eModulData.roomId,
            // groupId: createEventData.group_id || eModulData.group_id,
            description: createEventData.description || eModulData.description,
            aditionalDescription:
              createEventData.aditionalDescription ||
              eModulData.aditionalDescription,
            imageURL: createEventData.imageURL || eModulData.imageURL,
          };

          const formData = new FormData();

          Object.entries(updatedData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          });

          const response = await updateEModule(
            eModulData.id,
            formData,
            authToken
          );

          const userData = {
            eModuleLiveId: eModulData.id,
            polaznici: polaznici,
            voditelji: voditelji,
          };

          await addUserToEModule(userData, authToken);

          const responseData = response.data;
          if (response.status === 200) {
            setIsLoading(false);
            setModalContent({
              image: Success,
              description: "Promjene su spremljene",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate(-1),
                  className: "modal-btn-success",
                },
              ],
            });
            setShowModal(true);
          } else {
            setIsLoading(false);
            setModalContent({
              image: Question,
              description: "Nešto je pošlo po krivu. Pokušajte ponovo",
              buttons: [
                {
                  label: "Uredu",
                  action: () => navigate(-1),
                  className: "",
                },
              ],
            });
            setShowModal(true);
            console.log("Error occurred:", responseData.opis);
          }
          resetCreateEventData();
          return responseData;
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          return null;
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      {isLoading && <LoaderModal />}
      <Wrapper>
        <Header>
          <BackgroundImage />
          {isCreateMode || isEditMode ? (
            <>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                style={{ display: "none", cursor: "pointer" }}
                onChange={handleImageUpload}
              />
              <ImageButton
                selectedImage={createEventData.showImage}
                onClick={clearSelectedImage}
              >
                <label htmlFor="imageInput">
                  {!createEventData.showImage &&
                  !showImage &&
                  !selectedImage &&
                  !createEventData.imageURL ? (
                    <ButtonColumn>
                      <img alt="" src={Plus} />
                      Dodaj sliku
                    </ButtonColumn>
                  ) : null}
                </label>
              </ImageButton>
            </>
          ) : (
            <ProgramImage backgroundimage={eModulData.imageURL} />
          )}
        </Header>
        <Title>
          {isCreateMode && <Title>Novi modul uživo</Title>}
          {isEditMode && (
            <>
              {formData.name}
              <UrediButton disabled>Uredi</UrediButton>
            </>
          )}
        </Title>
        <InputWrapper>
          <InputContainer>
            <SectionTitle>Naziv modula</SectionTitle>
            <Input
              type="text"
              name="name"
              placeholder="Upiši"
              defaultValue={createEventData.name || formData.name}
              onChange={handleInputChange}
            />
          </InputContainer>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Godine eOR-a</SectionTitle>
              <Select
                type="text"
                name="e_or_year"
                value={createEventData.e_or_year || formData.e_or_year}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              >
                <option value="" disabled selected>
                  Odaberi
                </option>
                {years.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <SectionTitle>Grupa</SectionTitle>
              <MultiSelect
                isMulti
                placeholder="Odaberi"
                name="groups"
                options={groups?.filter(
                  (option) => formData.e_or_year == option.programId
                )}
                onChange={(selectedOptions) =>
                  handleSelectChange(
                    selectedOptions,
                    formData,
                    setFormData,
                    createEventData,
                    updateCreateEventData
                  )
                }
              />
            </InputContainer>
          </DatePlaceRow>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Od datuma</SectionTitle>
              <Input
                type="date"
                id="dob"
                name="dateFrom"
                value={
                  isEditMode
                    ? createEventData.dateFrom ||
                      eModulData.dateFrom.split("T")[0]
                    : createEventData.dateFrom || formData.dateFrom
                }
                placeholder="Odaberi"
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <SectionTitle>Do datuma</SectionTitle>
              <Input
                type="date"
                id="dob"
                name="dateTo"
                value={
                  isEditMode
                    ? createEventData.dateTo || eModulData.dateTo.split("T")[0]
                    : createEventData.dateTo || formData.dateTo
                }
                placeholder="Odaberi"
                onChange={handleInputChange}
              />
            </InputContainer>
          </DatePlaceRow>
          <SmallInputContainer>
            <SectionTitle>Mjesto održavanja</SectionTitle>
            <Select
              type="text"
              name="roomId"
              value={createEventData.roomId || formData.roomId}
              onChange={(e) => handleRoomSelection(e.target.value)}
              style={{ width: "100%" }}
            >
              <option value="" disabled selected>
                Odaberi prostoriju
              </option>
              {rooms.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </SmallInputContainer>
          <SectionTitle>Opis</SectionTitle>
          <TextArea
            placeholder="Dodaj opis"
            name="description"
            defaultValue={createEventData.description || formData.description}
            rows={5}
            cols={50}
            onChange={handleInputChange}
          />
          <SectionTitle>Dodatni opis</SectionTitle>
          <TextArea
            placeholder="Dodaj dodatne informacije"
            name="aditionalDescription"
            value={formData.aditionalDescription}
            rows={5}
            cols={50}
            onChange={handleInputChange}
          />
        </InputWrapper>
        <TitleBold>
          Voditelji {createEventData?.voditeljiData?.length}
        </TitleBold>
        <VoditeljiContainer>
          {createEventData?.voditeljiData?.length > 0 ? (
            <>
              {createEventData?.voditeljiData?.map((item, index) => (
                <VoditeljiCard
                  key={index}
                  voditeljAvatar={item.imageUrl}
                  voditeljIme={item.firstName}
                  voditeljPrezime={item.lastName}
                  voditeljBiografija={item.bio}
                  voditeljEmail={item.emailAddress}
                  deleteVoditelj={() => deleteVoditelj(item.id)}
                />
              ))}
              <AddVoditeljButton>
                <ButtonColumn
                  onClick={() =>
                    navigate("/djelatnici", {
                      state: {
                        isAddVoditeljToEvent: true,
                        isAddVoditelj: false,
                        voditeljiNow: createEventData.voditeljiData,
                      },
                    })
                  }
                >
                  <img alt="" src={Plus} />
                  Dodaj voditelja
                </ButtonColumn>
              </AddVoditeljButton>
            </>
          ) : (
            <AddVoditeljButton>
              <ButtonColumn
                onClick={() =>
                  navigate("/djelatnici", {
                    state: {
                      isAddVoditeljToEvent: true,
                      isAddVoditelj: false,
                      voditeljiNow: createEventData.voditeljiData,
                    },
                  })
                }
              >
                <img alt="" src={Plus} />
                Dodaj voditelja
              </ButtonColumn>
            </AddVoditeljButton>
          )}
        </VoditeljiContainer>
        <SectionTitleRow onClick={() => toggleDropdown()}>
          <TitleBold>
            Polaznici {createEventData?.polazniciData?.length}
          </TitleBold>
          <DropdownArrow alt="" src={Down} rotated={showTable} />
        </SectionTitleRow>
        {!isCreateMode && (
          <>
            {showTable && (
              <TableContainer>
                <TablePolaznici
                  columnNames={columnNames}
                  data={createEventData?.polazniciData}
                />
              </TableContainer>
            )}
          </>
        )}
        <EditFooter>
          {!isCreateMode && (
            <EditButton
              color="#ffffff"
              backgroundColor="#CB2D2D"
              onClick={() => handleDeleteEModule()}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              Obriši
              <img src={Trash} alt="" />
            </EditButton>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 10,
              width: "100%",
            }}
          >
            <EditButton
              color="#808080"
              backgroundColor="#EBEBEB"
              onClick={() => {
                navigate("/programi/eight");
                resetCreateEventData();
              }}
            >
              Odustani
            </EditButton>
            <EditButton
              color="#FFFFFF"
              backgroundColor="#CB9C2D"
              // disabled={!areAllFieldsFilled(formData)}
              onClick={handleSubmit}
            >
              {isCreateMode ? "Kreiraj radionicu" : " Spremi promjene"}
            </EditButton>
          </div>
        </EditFooter>
        {showModal && (
          <Modal
            image={modalContent.image}
            description={modalContent.description}
            buttons={modalContent.buttons}
            fullWidth={true}
          />
        )}
      </Wrapper>
    </>
  );
}

export default EModuliEditCreate;
