import CheckIcon from "../../assets/svg/CheckIcon";
import styles from "./Checkbox.module.css";

const Checkbox = ({
  name,
  label,
  value,
  error,
  onChange,
  onBlur,
  disabled = false,
}) => {
  return (
    <label className={styles.checkboxContainer}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        style={{ borderColor: error ? "red" : "#d1d9e2" }}
        onChange={onChange}
        onBlur={onBlur}
        className={styles.checkbox}
        disabled={disabled}
      />
      <p className={styles.checkboxLabelText}>{label}</p>
      {value && (
        <div className={styles.iconContainer}>
          <CheckIcon className={styles.icon} />
        </div>
      )}
    </label>
  );
};
export default Checkbox;
