import { Box, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import Left from "../../assets/svg/ChevronLeft.svg";
import Right from "../../assets/svg/TabRight.svg";

function ReusableTabs({
  currentTabIndex,
  handleTabChange,
  tabsData,
  tabOccurrences,
  isTabOccurences,
  ORNumber,
  eORNumber,
}) {
  useEffect(() => {
    if (!currentTabIndex && tabsData.length > 0) {
      handleTabChange(null, tabsData[0].value);
    }
  }, [currentTabIndex, tabsData, handleTabChange]);

  return (
    <Box
      className="tab-navigator"
      sx={{ maxWidth: { xs: 1000, sm: 1300 }, bgcolor: "background.paper" }}
    >
      <Tabs
        value={currentTabIndex}
        onChange={(event, newValue) => {
          handleTabChange(event, newValue);
        }}
        sx={{ borderBottom: "1px solid #E2E2E2" }}
        textColor="inherit"
        className="custom-tabs my-special-tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#CB9C2D",
          },
        }}
        aria-label="secondary tabs example"
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={(props) => {
          const isLeftButton = props.direction === "left" && !props.disabled;
          const isRightButton = props.direction === "right" && !props.disabled;
          const buttonStyles = {
            position: "absolute",
            top: 140,
            right: 180,
          };
          return (
            <IconButton
              {...props}
              style={isLeftButton || isRightButton ? buttonStyles : {}}
            >
              {isLeftButton && <img alt="" src={Left} />}
              {isRightButton && <img alt="" src={Right} />}
            </IconButton>
          );
        }}
      >
        {tabsData.map((tab) => (
          <Tab
            sx={{
              color: currentTabIndex === tab.value ? "#CB9C2D" : "#9FA7B2",
              textTransform: "none",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: 16,
              letterSpacing: "-0.1%",
            }}
            key={tab.value}
            value={tab.value}
            label={
              isTabOccurences ? (
                <>
                  {tabOccurrences[tab.value] ? (
                    <span>
                      {tab.label}
                      <span style={{ color: "#D1D9E2", fontWeight: 600 }}>
                        {" "}
                        {tabOccurrences[tab.value]}
                      </span>
                    </span>
                  ) : (
                    tab.label
                  )}
                </>
              ) : (
                <>
                  {ORNumber ? (
                    <span>
                      {tab.label}
                      <span style={{ color: "#D1D9E2", fontWeight: 600 }}>
                        {" "}
                        {ORNumber}
                      </span>
                    </span>
                  ) : eORNumber ? (
                    <span>
                      {tab.label}
                      <span style={{ color: "#D1D9E2", fontWeight: 600 }}>
                        {" "}
                        {eORNumber}
                      </span>
                    </span>
                  ) : (
                    tab.label
                  )}
                </>
              )
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default ReusableTabs;
