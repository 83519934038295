import styles from "./CustomInput.module.css";

const CustomInput = ({
  labelText,
  placeholder,
  type,
  name,
  onBlur,
  onChange,
  error,
  value,
  disabled,
  defaultValue,
}) => {
  return (
    <label className={styles.inputLabel}>
      <p className={styles.inputLabelText}>{labelText}</p>
      <input
        style={{ borderColor: error ? "red" : "#d1d9e2" }}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        className={styles.input}
        disabled={disabled}
        defaultValue={defaultValue || value}
      />
    </label>
  );
};
export default CustomInput;
