export const Referada = [
  {
    icon: require("../../assets/img/HomeIcon.png"),
    title: "Početna",
    pathname: "/dashboard",
  },
  {
    icon: require("../../assets/img/Programs.png"),
    title: "Programi",
    pathname: "/programi/one",
  },
  {
    icon: require("../../assets/img/PolazniciIcon.png"),
    title: "Polaznici",
    pathname: "/polaznici",
  },
  {
    icon: require("../../assets/img/SidemenuGrupe.png"),
    title: "Grupe",
    pathname: "/grupe",
  },
  {
    icon: require("../../assets/img/SidemenuIPDTim.png"),
    title: "Školski tim",
    pathname: "/djelatnici",
  },
  {
    icon: require("../../assets/img/OsvrtiIcon.png"),
    title: "Osvrti na voditelje",
    pathname: "/osvrti/one",
  },
  {
    icon: require("../../assets/img/TerapeutiIcon.png"),
    title: "Terapeuti",
    pathname: "/terapeuti",
  },
  {
    icon: require("../../assets/img/PaymentIcon.png"),
    title: "Plaćanja i narudžbe",
    pathname: "https://ipd-payment-form-rn389.ondigitalocean.app/products",
  },
  {
    icon: require("../../assets/img/DokumentiIcon.png"),
    title: "Dokumenti",
    pathname: "/dokumenti",
  },
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar",
  },
  // {
  //   icon: require("../../assets/img/SobeIcon.png"),
  //   title: "Sobe",
  //   pathname: "/sobe", //zavisno od role ide page
  // },
];
export const Polaznik = [
  {
    icon: require("../../assets/img/HomeIcon.png"),
    title: "Početna",
    pathname: "/dashboard",
  },
  {
    icon: require("../../assets/img/Programs.png"),
    title: "Programi",
    pathname: "/programi/one", //ovdje su programi koji su upisani/završeni od strane polaznika/terapeuta
  },
  {
    icon: require("../../assets/img/Prijavnice.png"),
    title: "Prijavnice",
    pathname: "/prijavnice/two", //vratit /one kad se fakultativne vrate
  },
  {
    icon: require("../../assets/img/SidemenuGrupe.png"),
    title: "Moje grupe",
    pathname: "/grupe",
  },
  {
    icon: require("../../assets/img/EvidencijaIcon.png"),
    title: "Moja evidencija",
    pathname: "/evidencija", //zavisno od role prikazati page za polaznika ili voditelja
  },
  {
    icon: require("../../assets/img/DokumentiIcon.png"),
    title: "Dokumenti",
    pathname: "/dokumenti", //isti page, ali treba dodati onClick navigate rute zavisno od role
  },
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar", //natrag na početnu treba voditi na dashboard (voditelj može vidjeti 2 kalendara znači kao referada, ali ne može uređivati master)
  },
  {
    icon: require("../../assets/img/TerapeutiIcon.png"),
    title: "Terapeuti",
    pathname: "/terapeuti", //ako terapeut udje u svoj terapeut profil može ga uredit, inače može kontaktirati drugog terapeuta
  },
  {
    icon: require("../../assets/img/OsvrtiIcon.png"),
    title: "Osvrti na voditelje",
    pathname: "/osvrti/one",
  },
  // {
  //   icon: require("../../assets/img/SobeIcon.png"),
  //   title: "Korištenje soba",
  //   pathname: "/sobe", //zavisno od role ide page
  //   show: false,
  // },
];
export const Voditelj = [
  // {
  //   icon: require("../../assets/img/SobeIcon.png"),
  //   title: "Korištenje soba",
  //   pathname: "/sobe", //zavisno od role ide page
  // },
  // {
  //   icon: require("../../assets/img/SidemenuIPDTim.png"),
  //   title: "Školski tim",
  //   pathname: "../../assets/img/SidemenuIPDTim.png",
  // },
  {
    icon: require("../../assets/img/DolasciIcon.png"),
    title: "Evidencija dolazaka",
    pathname: "/evidencija-dolazaka",
  },
  {
    icon: require("../../assets/img/EvidencijaPolazniciIcon.png"),
    title: "Evidencija polaznika",
    pathname: "/evidencijaVoditeljPolaznik",
  },
];

export const OstaleRole = [
  {
    icon: require("../../assets/img/HomeIcon.png"),
    title: "Početna",
    pathname: "/dashboard",
  },
  {
    icon: require("../../assets/img/Programs.png"),
    title: "Programi",
    pathname: "/programi/one", //ovdje su programi koji su upisani/završeni od strane polaznika/terapeuta
  },
  {
    icon: require("../../assets/img/Prijavnice.png"),
    title: "Prijavnice",
    pathname: "/prijavnice/two",
  },
  {
    icon: require("../../assets/img/SidemenuGrupe.png"),
    title: "Moje grupe",
    pathname: "/grupe",
  },
  {
    icon: require("../../assets/img/EvidencijaIcon.png"),
    title: "Moja evidencija",
    pathname: "/evidencija", //zavisno od role prikazati page za polaznika ili voditelja
  },
  {
    icon: require("../../assets/img/DokumentiIcon.png"),
    title: "Dokumenti",
    pathname: "/dokumenti", //isti page, ali treba dodati onClick navigate rute zavisno od role
  },
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar", //natrag na početnu treba voditi na dashboard (voditelj može vidjeti 2 kalendara znači kao referada, ali ne može uređivati master)
  },
  {
    icon: require("../../assets/img/TerapeutiIcon.png"),
    title: "Terapeuti",
    pathname: "/terapeuti", //ako terapeut udje u svoj terapeut profil može ga uredit, inače može kontaktirati drugog terapeuta
  },
  {
    icon: require("../../assets/img/OsvrtiIcon.png"),
    title: "Osvrti na voditelje",
    pathname: "/osvrti/one",
  },
  {
    icon: require("../../assets/img/DolasciIcon.png"),
    title: "Evidencija dolazaka",
    pathname: "/evidencija-dolazaka",
  },
  {
    icon: require("../../assets/img/EvidencijaPolazniciIcon.png"),
    title: "Evidencija polaznika",
    pathname: "/evidencijaVoditeljPolaznik",
  },
  // {
  //   icon: require("../../assets/img/SobeIcon.png"),
  //   title: "Korištenje soba",
  //   pathname: "/sobe", //zavisno od role ide page
  // },
  // {
  //   icon: require("../../assets/img/SidemenuIPDTim.png"),
  //   title: "Školski tim",
  //   pathname: "../../assets/img/SidemenuIPDTim.png",
  // },
  // {
  //   icon: require("../../assets/img/DolasciIcon.png"),
  //   title: "Evidencija dolazaka",
  //   pathname: "/evidencija-dolazaka", //nema page napravljen
  // },
  // {
  //   icon: require("../../assets/img/EvidencijaPolazniciIcon.png"),
  //   title: "Evidencija polaznika",
  //   pathname: "/evidencijaVoditeljPolaznik",
  // },
];

export const Zakupitelj = [
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar",
  },
  {
    icon: require("../../assets/img/SobeIcon.png"),
    title: "Korištenje soba",
    pathname: "/sobe",
  },
];
