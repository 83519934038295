import React, { createContext, useContext, useState } from "react";

const CreateEventContext = createContext();

const CreateEventProvider = ({ children }) => {
  const initialCreateEventData = {
    name: "",
    dateFrom: "",
    dateTo: "",
    roomId: null,
    // groupId: null,
    description: "",
    aditionalDescription: "",
    imageURL: "",
    date: "",
    timeFrom: "",
    timeTo: "",
    price: "",
    gprHours: null,
    capacity: null,
    masterAbbreviation: "",
    isWeekend: null,
    time: "",
    intensiveType: "",
    showImage: "",
    voditeljiData: [],
    polazniciData: [],
  };
  const [createEventData, setCreateEventData] = useState(
    initialCreateEventData
  );
  const updateCreateEventData = (data) => {
    setCreateEventData(data);
  };
  const resetCreateEventData = () => {
    setCreateEventData(initialCreateEventData);
  };

  return (
    <CreateEventContext.Provider
      value={{ createEventData, updateCreateEventData, resetCreateEventData }}
    >
      {children}
    </CreateEventContext.Provider>
  );
};
export const useCreateEventState = () => useContext(CreateEventContext);

export default CreateEventProvider;
